import GreyCircle from "../images/shapes/grey-circle.png";
import GreyTriangle from "../images/shapes/grey-triangle.png";
import GreySquare from "../images/shapes/grey-square.png";
import GreyPentagon from "../images/shapes/grey-pentagon.png";
import GreyTrapezoid from "../images/shapes/grey-trapezoid.png";
import GreyDiamond from "../images/shapes/grey-diamond.png";

/**
 * Constant that holds images for every image available in the avatar selection process.
 * Avatars are accessed by indices from 0 to 24, which is held in React state.
 * @author Eric Doppelt
 */

const MainPlayerImages = {
  images: {
    0: GreyCircle,
    1: GreyTriangle,
    2: GreySquare,
    3: GreyPentagon,
    4: GreyTrapezoid,
    5: GreyDiamond
  }
}

export default MainPlayerImages;
