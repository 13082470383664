import React, { useEffect, useState } from "react";
import "../util/stylings/FullScreenDiv.css";
import PlayerColumn from "./components/PlayerColumn";
import { Grid, Typography, withStyles } from "@material-ui/core";
import MovingComponent from 'react-moving-text';
import ConfirmButton from "./components/ConfirmButton";
import { withRouter } from "react-router-dom";
import GroupBox from "./components/GroupBox";
import GameTimer from "../util/components/GameTimer";
import getAlerts from './components/getAlerts';
import BonusShower from './components/BonusShower';
import WaitingDiv from "../util/components/WaitingDiv";
import GameOneHelp from "./components/GameOneHelp";

import Routes from '../util/constants/routes';

const FULL_DIV = "fullDiv";
const BOTTOM_OF_SCREEN = 100;
const INITIAL_HEIGHT = 50;
const NUM_PLAYERS = 6;
const MAX_PLAYERS_SELECTED = 2;
const PLAYERS = [0, 1, 2, 3, 4, 5];
const NOT_SELECTED = false;
const DONT_SUBMIT_DECISIONS = false;
const SELECTED_SELF = true;
const DID_NOT_SELECT_SELF = false;
const TOO_MANY_SELECTIONS = true;
const NOT_TOO_MANY_SELECTS = false;

const RESET_TIMER = true;
const DO_NOT_RESET_TIMER = false;

var PAUSE_BETWEEN_ANIMATIONS = 2000;
const IN_BONUS = true;

const GROUP_ONE = "One";
const GROUP_TWO = "Two";
const GRID_DIRECTION = "row";
const GRID_JUSTIFICATION = "center";
const GROUP_BOX_WIDTH = "60vw";
const ANIMATED_COLUMNS_HEIGHT = "80vh";

const DISABLE_BUTTON = true;
const DO_NOT_DISABLE_BUTTON = false;

const DISABLE_PLAYERS = createPlayerArray(true);
const ENABLE_PLAYERS = createPlayerArray(false);

const INITIAL_TIME_LEFT = -1;
const DONT_NOTE_TIME = false;

const LARGE_SPACING_WIDTH = 1350;
const LARGE_SPACING = 10;

const MEDIUM_SPACING_WIDTH = 1275;
const MEDIUM_SPACING = 9;

const SMALL_SPACING_WIDTH = 1150;
const SMALL_SPACING = 7;

const SMALLER_SPACING_WIDTH = 950;
const SMALLER_SPACING = 6;

const SMALLEST_SPACING = 5;

const SINGLE_BONUS = 'single';
const DOUBLE_BONUS = 'double';
const TRIPLE_BONUS = 'triple';

const OPEN = true;
const CLOSED = false;

const SHOW_DIV = true
const HIDE_DIV = false

const styles = {
    animatedColumns: {
        position: "absolute",
        top: "8vh",
        left: "19vw",
        height: "90vh",
        width: "85vw",
        borderRadius: "20px",
        alignItems: "center",
        verticalAlign: "middle",
    },
    groupTwoBox: {
        position: "absolute",
        top: "77vh",
        left: '13vw'
    },
    tutorialPopup: {
        fontFamily: "sans-serif",
        verticalAlign: "middle",
        fontSize: "30px",
        padding: '30px',
        position: "absolute",
        borderRadius: "20px",
        backgroundColor: "#fad461",
        width: "50vw",
        top: "25vh",
        left: "25vw",
        lineHeight: "50px",
        zIndex: "2"
    }
};

/**
 * All-encompassing class used to run the first game of the psych experiment.
 * Leverages components in the subpackage game_one.components to create a game following the rules outlined in the GameOneTutorial.png file.
 * @param {*} props is used to prove the login code of the player and all login codes for all players.
 * 
 * @author Eric Doppelt
 */
function GameOne(props) {
    const [startHeights, setStartHeights] = useState(createPlayerArray(BOTTOM_OF_SCREEN));
    const [endHeights, setEndHeights] = useState(createPlayerArray(INITIAL_HEIGHT));
    const [currentHeight, setCurrentHeight] = useState(createPlayerArray(INITIAL_HEIGHT));

    let initialSelections = createPlayerArray(NOT_SELECTED);
    const [selected, setSelected] = useState(initialSelections);
    const [doubles, setDoubles] = useState(initialSelections);
    const [triples, setTriples] = useState(initialSelections);
    const [singles, setSingles] = useState(initialSelections);

    const [disabledPlayers, setDisabledPlayers] = useState(createPlayerArray())
    const [selectedSelf, setSelectedSelf] = useState(DID_NOT_SELECT_SELF);
    const [tooManySelects, setTooManySelects] = useState(NOT_TOO_MANY_SELECTS);
    const [notEnoughSelects, setNotEnoughSelects] = useState(false); // alert for when the player clicks confirm without selecting two other players

    const [resetTimer, setResetTimer] = useState(DO_NOT_RESET_TIMER);
    const [pauseTimer, setPauseTimer] = useState()
    const [submitDecisions, setSubmitDecisions] = useState(DONT_SUBMIT_DECISIONS);
    const [disableButton, setDisableButton] = useState(DO_NOT_DISABLE_BUTTON);
    const [showWaitingDiv, setShowWaitingDiv] = useState(HIDE_DIV);
    const [timeLeft, setTimeLeft] = useState(INITIAL_TIME_LEFT);
    const [noteTime, setNoteTime] = useState(DONT_NOTE_TIME);

    const [bonusType, setBonusType] = useState(DOUBLE_BONUS);
    const [openBonusShower, setOpenBonusShower] = useState(CLOSED);

    const [madeMove, setMadeMove] = useState(true);
    const [animationPause, setAnimationPause] = useState(0);
    const [tutorialText, setTutorialText] = useState("");
    const [showTutorialAlert, setShowTutorialAlert] = useState(false);
    const [showTimer, setShowTimer] = useState(false);

    // return to initial login page if state is null
    if (!props.currentState) {
        props.history.push(Routes.LOGIN);
        return (<div></div>);
    }

    // get round length from round end time in state
    const roundEndTime = Date.parse(props.currentState.roundEndTime);
    const time = new Date();
    const roundLength = roundEndTime - time.getTime();

    // loop through player ids and swap self index with 0 so self always appears first
    var playerIndices = PLAYERS;
    for (var i = 0; i < props.playerData.length; i++) {
        if (props.playerData[i].id == props.id) {
            // swap index 0 with index i
            playerIndices[i] = 0;
            playerIndices[0] = i;
            break;
        }
    }

    // handle state update
    useEffect(() => {
        if (props.currentState.type != "game-one_state") {
            return;
        }

        // clear highlighted players and selection
        clearSelected(setSelected);
        setSubmitDecisions(DONT_SUBMIT_DECISIONS);
        setShowTimer(false);

        // open popup if no move made
        if (!madeMove) {
            props.setPassiveDialogueOpen(true);
        }
        setMadeMove(false);

        // tutorial popup appears if round one
        if (props.currentState.round == 0) {
            setShowTutorialAlert(true);
            setShowTimer(false);
            setTimeout(() => {
                setTutorialText("The round is a tutorial round, results will NOT be recorded. Try selecting the triangle and square players and press CONFIRM! to submit your move.");
                setShowTutorialAlert(false);
                setShowTimer(true);
            }, 10000);
        } else {
            setTutorialText("");
        }

        // get list of player ids
        const ids = [];
        for (var i = 0; i < props.playerData.length; i++) {
            ids.push(props.playerData[i].id);
        }

        // handle player rejoining the game
        if (props.rejoined) {
            // render final heights without animation
            var length = props.currentState.bonusGroups.length;
            var heightsFromState = [];
            for (var i = 0; i < props.currentState.bonusGroups[length - 1].length; i++) {
                const scaledPosition = -1 * (props.currentState.bonusGroups[length - 1][i].position * 30) + 30;
                heightsFromState.push(scaledPosition);
            }
            setEndHeights(heightsFromState);
            setCurrentHeight(heightsFromState);
            props.setRejoined(false);

            // disable submission for this round
            setDisableButton(true);
            setShowTimer(true);
        } else if (props.currentState.bonusGroups.length == 1) {
            // if no bonus groups, go to initial player heights
            var heightsFromState = [];
            for (var i = 0; i < props.currentState.bonusGroups[0].length; i++) {
                const scaledPosition = -1 * (props.currentState.bonusGroups[0][i].position * 30) + 30;
                heightsFromState.push(scaledPosition);
            }
            setEndHeights(heightsFromState);
            setCurrentHeight(heightsFromState);
            setShowTimer(true);
        } else {
            // go to initial heights, and then handle bonus group animations
            var heightsFromState = [];
            for (var i = 0; i < props.currentState.bonusGroups[0].length; i++) {
                const scaledPosition = -1 * (props.currentState.bonusGroups[0][i].position * 30) + 30;
                heightsFromState.push(scaledPosition);
            }
            setEndHeights(heightsFromState);
            setCurrentHeight(heightsFromState);

            // handle bonuses
            const tripleBonusArray = [];
            const doubleBonusArray = [];
            const singleBonusArray = [];
            const tripleHeightArray = [];
            const doubleHeightArray = [];
            const singleHeightArray = [];

            var bonusGroups = props.currentState.bonusGroups;

            // if in round 1 right after the trial round (round 0), override bonus groups with hardcoded groups to demonstrate the game
            if (props.currentState.round == 1) {
                var tutorialBonusGroups = [
                    props.currentState.bonusGroups[0],
                    [
                        {
                            "id": ids[playerIndices[5]],
                            "position": 0.05,
                            "turnBonus": "single"
                        }
                    ],
                    [
                        {
                            "id": ids[playerIndices[4]],
                            "position": 0.08,
                            "turnBonus": "double"
                        },
                        {
                            "id": ids[playerIndices[3]],
                            "position": 0.08,
                            "turnBonus": "double"
                        }
                    ],
                    [
                        {
                            "id": ids[playerIndices[0]],
                            "position": 0.1,
                            "turnBonus": "triple"
                        },
                        {
                            "id": ids[playerIndices[1]],
                            "position": 0.1,
                            "turnBonus": "triple"
                        },
                        {
                            "id": ids[playerIndices[2]],
                            "position": 0.1,
                            "turnBonus": "triple"
                        }
                    ],
                    [
                        {
                            "id": ids[0],
                            "position": 0,
                            "turnBonus": "none"
                        },
                        {
                            "id": ids[1],
                            "position": 0,
                            "turnBonus": "none"
                        },
                        {
                            "id": ids[2],
                            "position": 0,
                            "turnBonus": "none"
                        },
                        {
                            "id": ids[3],
                            "position": 0,
                            "turnBonus": "none"
                        },
                        {
                            "id": ids[4],
                            "position": 0,
                            "turnBonus": "none"
                        },
                        {
                            "id": ids[5],
                            "position": 0,
                            "turnBonus": "none"
                        }
                    ]
                ];

                bonusGroups = tutorialBonusGroups;
            }

            // loop through all bonus groups
            const length = bonusGroups.length;
            for (var bonusIndex = 1; bonusIndex < length - 1; bonusIndex++) {
                if (bonusGroups[bonusIndex][0].turnBonus == "single") {
                    // make single bonus group
                    var singleGroup = [];
                    var singleGroupHeight = [];
                    for (var player = 0; player < bonusGroups[bonusIndex].length; player++) {
                        singleGroup.push(bonusGroups[bonusIndex][player].id);
                        singleGroupHeight.push(-1 * (bonusGroups[bonusIndex][player].position * 30) + 30);
                    }
                    singleBonusArray.push(singleGroup);
                    singleHeightArray.push(singleGroupHeight);
                } else if (bonusGroups[bonusIndex][0].turnBonus == "double") {
                    // make double bonus group
                    var doubleGroup = [];
                    var doubleGroupHeight = [];
                    for (var player = 0; player < bonusGroups[bonusIndex].length; player++) {
                        doubleGroup.push(bonusGroups[bonusIndex][player].id);
                        doubleGroupHeight.push(-1 * (bonusGroups[bonusIndex][player].position * 30) + 30);
                    }
                    doubleBonusArray.push(doubleGroup);
                    doubleHeightArray.push(doubleGroupHeight);
                } else if (bonusGroups[bonusIndex][0].turnBonus == "triple") {
                    // make triple bonus group
                    var tripleGroup = [];
                    var tripleGroupHeight = [];
                    for (var player = 0; player < bonusGroups[bonusIndex].length; player++) {
                        tripleGroup.push(bonusGroups[bonusIndex][player].id);
                        tripleGroupHeight.push(-1 * (bonusGroups[bonusIndex][player].position * 30) + 30);
                    }
                    tripleBonusArray.push(tripleGroup);
                    tripleHeightArray.push(tripleGroupHeight);
                }
            }

            // handle and animate triple bonuses
            if (props.currentState.round == 1) {
                PAUSE_BETWEEN_ANIMATIONS = 9000;
                setTutorialText("You (circle), the triangle, and the square players selected each other to form a triplet, so all three of you get a triple bonus.");
            } else {
                PAUSE_BETWEEN_ANIMATIONS = 2000;
            }
            const tripleIncrease = 20;
            setShowWaitingDiv(HIDE_DIV);
            let posAfterTriple = handleTripleBonuses(
                setCurrentHeight,
                tripleBonusArray,
                tripleHeightArray,
                tripleIncrease,
                ids,
                setStartHeights,
                setEndHeights,
                heightsFromState,
                setTriples,
                setBonusType,
                setOpenBonusShower
            );

            let tripleBonusPause = tripleBonusArray.length * PAUSE_BETWEEN_ANIMATIONS;
            clearBonusArray(setTriples, tripleBonusPause);

            // handle and animate double bonuses
            if (props.currentState.round == 1) {
                setTimeout(() => {
                    PAUSE_BETWEEN_ANIMATIONS = 9000;
                    setTutorialText("The pentagon and trapezoid players selected each other to form a pair, so both players get a double bonus.");
                }, tripleBonusPause);
            } else {
                PAUSE_BETWEEN_ANIMATIONS = 2000;
            }

            const doubleIncrease = 10;
            let posAfterDouble = handleDoubleBonuses(
                setCurrentHeight,
                doubleBonusArray,
                doubleHeightArray,
                doubleIncrease,
                ids,
                setStartHeights,
                setEndHeights,
                posAfterTriple,
                setDoubles,
                tripleBonusArray.length,
                setBonusType,
                setOpenBonusShower
            );

            let tripleDoubleBonusPause = (tripleBonusArray.length + doubleBonusArray.length) * PAUSE_BETWEEN_ANIMATIONS;
            clearBonusArray(setDoubles, tripleDoubleBonusPause);

            // handle and animate single bonuses
            if (props.currentState.round == 1) {
                setTimeout(() => {
                    PAUSE_BETWEEN_ANIMATIONS = 9000;
                    setTutorialText("The diamond player was selected by another player, so this player gets a single bonus.");
                }, tripleDoubleBonusPause);
            } else {
                PAUSE_BETWEEN_ANIMATIONS = 2000;
            }

            const singleIncrease = 5;
            let posAfterSingle = handleSingleBonuses(
                setCurrentHeight,
                singleBonusArray,
                singleHeightArray,
                singleIncrease,
                ids,
                setStartHeights,
                setEndHeights,
                posAfterDouble,
                setSingles,
                doubleBonusArray.length + tripleBonusArray.length,
                setBonusType,
                setOpenBonusShower
            );

            // get final heights
            let finalHeights = [];
            for (var i = 0; i < bonusGroups[length - 1].length; i++) {
                const scaledPosition = -1 * (bonusGroups[length - 1][i].position * 30) + 30;
                finalHeights.push(scaledPosition);
            }

            // display message for transitioning to next round
            let allBonusPause = (tripleBonusArray.length + doubleBonusArray.length + singleBonusArray.length) * PAUSE_BETWEEN_ANIMATIONS;
            clearBonusArray(setSingles, allBonusPause);
            setTimeout(() => {
                setOpenBonusShower(OPEN);
                setOpenBonusShower("transition");
            }, allBonusPause);

            // popup for end of tutorial round
            if (props.currentState.round == 1) {
                setTimeout(() => {
                    setTutorialText("Resetting player positions. From the present round onward, results will be recorded.");
                }, allBonusPause);

                setTimeout(() => {
                    setTutorialText("");
                    setShowTutorialAlert(true);
                }, allBonusPause + PAUSE_BETWEEN_ANIMATIONS);


                setTimeout(() => {
                    setShowTutorialAlert(false);
                }, allBonusPause + PAUSE_BETWEEN_ANIMATIONS + 8000);
            }

            // animate players travelling to final heights
            let finalPause = allBonusPause + PAUSE_BETWEEN_ANIMATIONS
            let scaledNewHeights = finalHeights;
            updateHeightsDelayed(
                setCurrentHeight,
                posAfterSingle,
                scaledNewHeights,
                setStartHeights,
                setEndHeights,
                allBonusPause,
                'none',
                setBonusType,
                setOpenBonusShower,
                OPEN);

            let allMovementPause = finalPause;
            setTimeout(() => {
                setOpenBonusShower(CLOSED);
                setShowTimer(true);
            }, allMovementPause);
            setAnimationPause(allMovementPause);
            handleDisablePlayers(allMovementPause, setDisabledPlayers);
            handleGameTimer(allMovementPause, setResetTimer, setPauseTimer);
            pauseSubmitButton(allMovementPause, setDisableButton);
        }
    }, [props.currentState]);

    // track number of other players selected
    var selectRemaining = 2;
    for (var i = 0; i < selected.length; i++) {
        if (selected[i]) {
            selectRemaining--;
        }
    }

    const { classes } = props;

    return (
        <div className={FULL_DIV}>
            {getAlerts(
                selectRemaining,
                notEnoughSelects,
                setNotEnoughSelects,
                selectedSelf,
                setSelectedSelf,
                tooManySelects,
                setTooManySelects
            )}
            {getTutorialAlert(
                props.currentState.round,
                showTutorialAlert
            )}
            {getTutorialTextBox(
                props.currentState.round,
                tutorialText
            )}
            <GameOneHelp />
            <WaitingDiv show={showWaitingDiv} windowWidth={props.windowWidth} />
            <BonusShower bonus={bonusType} open={openBonusShower} windowWidth={props.windowWidth} />
            {showTimer && <GameTimer // just display end minus current time, needs to end at correct time
                roundLength={roundLength}
                setSubmitDecisions={setSubmitDecisions}
                resetTimer={resetTimer}
                setResetTimer={setResetTimer}
                pauseTimer={pauseTimer}
                noteTime={noteTime}
                setNoteTime={setNoteTime}
                setTimeLeft={setTimeLeft}
                windowWidth={props.windowWidth}
                disabled={disableButton}
                disableButton={() => setDisableButton(DISABLE_BUTTON)}
                setPassiveDialogueOpen={props.setPassiveDialogueOpen}
            />
            }
            {showTimer && <ConfirmButton
                id={props.id}
                currentState={props.currentState}
                submit={submitDecisions}
                setSubmit={setSubmitDecisions}
                clearSubmission={() => setSubmitDecisions(DONT_SUBMIT_DECISIONS)}
                selected={selected}
                clearSelected={() => clearSelected(setSelected)}
                disabled={disableButton}
                disableButton={() => setDisableButton(DISABLE_BUTTON)}
                timeLeft={timeLeft}
                setNoteTime={setNoteTime}
                windowWidth={props.windowWidth}
                showWaitingDiv={() => setShowWaitingDiv(SHOW_DIV)}
                madeMove={madeMove}
                setMadeMove={setMadeMove}
                roundStartTime={props.currentState.roundStartTime}
                animationPause={animationPause}
                setNotEnoughSelects={setNotEnoughSelects}
                setTutorialText={setTutorialText}
            />
            }

            <div className={classes.animatedColumns}>
                <GroupBox groupNumber={GROUP_ONE} width={GROUP_BOX_WIDTH} />
                <Grid
                    container
                    direction={GRID_DIRECTION}
                    justify={GRID_JUSTIFICATION}
                    spacing={getSpacing(props.windowWidth)}
                    style={{ height: { ANIMATED_COLUMNS_HEIGHT } }}
                >
                    {playerIndices.map((player) => {
                        return getColumn(
                            props.id,
                            props.playerData,
                            playerIndices,
                            props.currentState.bonusGroups,
                            player,
                            selected,
                            setSelected,
                            setSelectedSelf,
                            setTooManySelects,
                            startHeights,
                            endHeights,
                            player,
                            singles,
                            doubles,
                            triples,
                            disabledPlayers,
                            props.currentState.round,
                            props.windowWidth,
                            props.windowHeight
                        );
                    })}
                </Grid>
                <div className={classes.groupTwoBox}>
                    <GroupBox groupNumber={GROUP_TWO} width={GROUP_BOX_WIDTH} />
                </div>
            </div>
        </div>
    );
}

function handleDisablePlayers(animationPause, setDisabledPlayers) {
    setDisabledPlayers(DISABLE_PLAYERS);
    setTimeout(() => {
        setDisabledPlayers(ENABLE_PLAYERS);
    }, animationPause);
}

function getSpacing(windowWidth) {
    if (windowWidth >= LARGE_SPACING_WIDTH) return LARGE_SPACING;
    else if (windowWidth >= MEDIUM_SPACING_WIDTH) return MEDIUM_SPACING;
    else if (windowWidth >= SMALL_SPACING_WIDTH) return SMALL_SPACING;
    else if (windowWidth >= SMALLER_SPACING_WIDTH) return SMALLER_SPACING;
    return SMALLEST_SPACING;
}

function handleGameTimer(animationPause, setResetTimer, setPauseTimer) {
    setResetTimer(RESET_TIMER);
}

function pauseSubmitButton(animationPause, setDisableButton) {
    setDisableButton(DISABLE_BUTTON);
    setTimeout(() => {
        setDisableButton(DO_NOT_DISABLE_BUTTON);
    }, animationPause);
}

function getColumn(id, playerData, playerIndices, bonusGroups, playerNumber, selected, setSelected, setSelectedSelf, setTooManySelections, fromHeights, toHeights, myID, singles, doubles, triples, disabledPlayers, round, windowWidth, windowHeight) {
    // myID is player index in array
    // get idObj from playerData array
    const idObj = playerData[myID].id;
    const avatarIndex = playerData[myID].avatar;
    var isSelf = false;
    if (idObj == id) {
        isSelf = true;
    }

    let doAnimate = false;
    if (toHeights[myID] != fromHeights[myID]) {
        doAnimate = true;
    }

    return (
        <Grid item>
            <PlayerColumn
                onSelect={() =>
                    selectPlayer(
                        playerData,
                        playerIndices,
                        playerNumber,
                        selected,
                        setSelected,
                        setSelectedSelf,
                        setTooManySelections,
                        id,
                        round
                    )}
                selected={selected[playerNumber]}
                single={singles[playerNumber]}
                double={doubles[playerNumber]}
                triple={triples[playerNumber]}
                from={fromHeights[playerNumber]}
                to={toHeights[playerNumber]}
                player={playerNumber}
                avatar={avatarIndex}
                disabled={disabledPlayers[playerNumber]}
                windowHeight={windowHeight}
                windowWidth={windowWidth}
                isSelf={isSelf}
                doAnimate={doAnimate}
            />
        </Grid>
    );
}

function handleTripleBonuses(setCurrentHeights, tripleArray, tripleHeightArray, tripleIncrease, allLoginCodes, setOldHeights, setNewHeights, originalHeights, setTriples, setBonusType, setOpenBonusShower) {
    let oldHeights = originalHeights.slice(0);
    let newHeights = [];
    for (let i = 0; i < originalHeights.length; i++) {
        newHeights.push(originalHeights[i]);
    }
    for (let i = 0; i < tripleArray.length; i++) {
        let loginCodes = tripleArray[i];
        newHeights = oldHeights.slice(0);
        let bonusGroupIndices = [];
        for (let j = 0; j < loginCodes.length; j++) {
            const playerIndex = getPlayerIndex(loginCodes[j], allLoginCodes);
            bonusGroupIndices.push(playerIndex);
            newHeights[playerIndex] = tripleHeightArray[i][j];
        }
        updateHeightsDelayed(setCurrentHeights, oldHeights, newHeights, setOldHeights, setNewHeights, i * PAUSE_BETWEEN_ANIMATIONS, TRIPLE_BONUS, setBonusType, setOpenBonusShower, OPEN);
        markTripleDelayed(bonusGroupIndices, setTriples, i * PAUSE_BETWEEN_ANIMATIONS);
        oldHeights = newHeights;
    }
    if (newHeights == null) return originalHeights.splice(0);
    else return newHeights;
}

function markTripleDelayed(bonusGroupIndices, setTriples, delay) {
    updateBonusArray(bonusGroupIndices, setTriples, delay);
}

function handleDoubleBonuses(setCurrentHeights, doubleArray, doubleHeightArray, doubleIncrease, allLoginCodes, setOldHeights, setNewHeights, originalHeights, setDoubles, animationOffset, setBonusType, setOpenBonusShower) {
    let oldHeights = originalHeights.slice(0);
    let newHeights = [];
    for (let i = 0; i < originalHeights.length; i++) {
        newHeights.push(originalHeights[i]);
    }
    for (let i = 0; i < doubleArray.length; i++) {
        let loginCodes = doubleArray[i];
        newHeights = oldHeights.slice(0);
        let bonusGroupIndices = [];
        for (let j = 0; j < loginCodes.length; j++) {
            const playerIndex = getPlayerIndex(loginCodes[j], allLoginCodes);
            bonusGroupIndices.push(playerIndex);
            newHeights[playerIndex] = doubleHeightArray[i][j];
        }
        updateHeightsDelayed(setCurrentHeights, oldHeights, newHeights, setOldHeights, setNewHeights, (i + animationOffset) * PAUSE_BETWEEN_ANIMATIONS, DOUBLE_BONUS, setBonusType, setOpenBonusShower, OPEN);
        markDoubleDelayed(bonusGroupIndices, setDoubles, (i + animationOffset) * PAUSE_BETWEEN_ANIMATIONS);
        oldHeights = newHeights;
    }
    if (newHeights == null) return originalHeights;
    else return newHeights;
}

function markDoubleDelayed(bonusGroupIndices, setDoubles, delay) {
    updateBonusArray(bonusGroupIndices, setDoubles, delay);
}

function handleSingleBonuses(setCurrentHeights, singleArray, singleHeightArray, singleIncrease, allLoginCodes, setOldHeights, setNewHeights, originalHeights, setSingles, animationOffset, setBonusType, setOpenBonusShower) {
    let oldHeights = originalHeights.slice(0);
    let newHeights = originalHeights.slice(0);
    for (let i = 0; i < singleArray.length; i++) {
        let loginCodes = singleArray[i];
        newHeights = oldHeights.slice(0);
        let bonusGroupIndices = [];
        for (let j = 0; j < loginCodes.length; j++) {
            const playerIndex = getPlayerIndex(loginCodes[j], allLoginCodes);
            bonusGroupIndices.push(playerIndex);
            newHeights[playerIndex] = singleHeightArray[i][j];
        }
        updateHeightsDelayed(setCurrentHeights, oldHeights, newHeights, setOldHeights, setNewHeights, (i + animationOffset) * PAUSE_BETWEEN_ANIMATIONS, SINGLE_BONUS, setBonusType, setOpenBonusShower, OPEN);
        markSingleDelayed(bonusGroupIndices, setSingles, (i + animationOffset) * PAUSE_BETWEEN_ANIMATIONS);
        oldHeights = newHeights;
    }
    if (newHeights == null) return originalHeights;
    else return newHeights;
}

function markSingleDelayed(bonusGroupIndices, setSingles, delay) {
    updateBonusArray(bonusGroupIndices, setSingles, delay);
}

function updateHeightsDelayed(setCurrentHeights, oldHeights, newHeights, setOldHeights, setNewHeights, delay, bonusType, setBonusType, setOpenBonusShower, openBonus) {
    setTimeout(() => {
        updateHeights(oldHeights, newHeights, setOldHeights, setNewHeights);
        setBonusType(bonusType);
        setOpenBonusShower(openBonus);
        if (bonusType == "none") {
            setCurrentHeights(newHeights);
        }
    }, delay);
}

function updateHeights(oldHeights, newHeights, setOldHeights, setNewHeights) {
    setOldHeights(oldHeights);
    setNewHeights(newHeights);
}

function clearBonusArray(setBonus, delay) {
    setTimeout(() => setBonus(createPlayerArray(NOT_SELECTED)), delay);
}

function updateBonusArray(indexArray, setBonus, delay) {
    let bonusArray = createPlayerArray(NOT_SELECTED);
    indexArray.forEach((index) => {
        bonusArray[index] = IN_BONUS;
    });
    setTimeout(() => setBonus(bonusArray), delay);
}

function clearSelected(setSelected) {
    setSelected(createPlayerArray(NOT_SELECTED));
}

function createPlayerArray(height) {
    let heights = new Array(NUM_PLAYERS);
    heights.fill(height);
    return heights;
}

function selectPlayer(playerData, playerIndices, player, selected, setSelected, setSelectedSelf, setTooManySelections, myID, round) {
    if (round == 0 && player != playerIndices[1] && player != playerIndices[2]) {
        return;
    }

    if (playerData[player].id == myID) {
        setSelectedSelf(SELECTED_SELF);
        setTimeout(() => {
            setSelectedSelf(false);
        }, 3000);
        return;
    }

    if (selected[player]) {
        let updatedSelection = selected.slice(0);
        updatedSelection[player] = !updatedSelection[player];
        setSelected(updatedSelection);
        return;
    }

    if (countSelectedPlayers(selected) < MAX_PLAYERS_SELECTED) {
        let updatedSelection = selected.slice(0);
        updatedSelection[player] = !updatedSelection[player];
        setSelected(updatedSelection);
    } else {
        setSelectedSelf(false);
        setTooManySelections(TOO_MANY_SELECTIONS);
        setTimeout(() => {
            setTooManySelections(false);
        }, 3000);
    }
}

function countSelectedPlayers(selected) {
    return getSelectedPlayers(selected).length;
}

function getSelectedPlayers(selected) {
    let selectedPlayers = [];
    for (let i = 0; i < NUM_PLAYERS; i++) {
        if (selected[i]) selectedPlayers.push(selected[i]);
    }
    return selectedPlayers;
}

function getPlayerIndex(loginCode, allLoginCodes) {
    for (let i = 0; i < allLoginCodes.length; i++) {
        if (allLoginCodes[i] === loginCode) return i;
    }
}

// get popup alerts with instructions for tutorial round
function getTutorialAlert(round, showTutorialAlert) {
    if (round == 0 && showTutorialAlert) {
        return (
            <MovingComponent
                type="popIn"
                duration="2000ms"
                delay="0ms"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none"
                style={styles.tutorialPopup}>
                The first round is a tutorial round for you to learn the game. Results of the first round will not be recorded. Try selecting the triangle and square players and press CONFIRM! to submit your move.
            </MovingComponent>
        );
    } else if (round == 1 && showTutorialAlert) {
        return (
            <MovingComponent
                type="popIn"
                duration="2000ms"
                delay="0ms"
                direction="normal"
                timing="ease"
                iteration="1"
                fillMode="none"
                style={styles.tutorialPopup}>
                The tutorial round has ended. Starting from now, results will be recorded. You can now select any of the other players.
            </MovingComponent>
        );
    } else {
        return (
            <div></div>
        );
    }
}

// get popup boxes with bonus explanations for tutorial rounds
function getTutorialTextBox(round, tutorialText) {
    if (tutorialText == "") {
        return (
            <Typography />
        );
    } else if (round == 0) {

        return (
            <Typography
                style={{
                    fontSize: '20px',
                    position: "absolute",
                    borderRadius: "20px",
                    backgroundColor: "#fad461",
                    width: "25vw",
                    top: "4vh",
                    left: "2vw",
                    padding: '10px',
                    lineHeight: "35px",
                    zIndex: 999,
                }}
            >
                {tutorialText}
            </Typography>
        );
    } else if (round == 1) {
        return (
            <Typography
                style={{
                    fontSize: '20px',
                    position: "absolute",
                    borderRadius: "20px",
                    backgroundColor: "#fad461",
                    width: "25vw",
                    top: "22vh",
                    left: "2vw",
                    zIndex: "999",
                    padding: '10px',
                    lineHeight: "35px",
                }}
            >
                {tutorialText}
            </Typography>
        );
    } else {
        return (
            <div>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(GameOne));