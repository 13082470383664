import React from "react";
import { useSpring, animated } from "react-spring";
import { withStyles } from "@material-ui/core";
import getBackgroundColor from "../../util/functions/getResourceBackgroundColor";
import getMarginLeft from "../../util/functions/getResourceMarginLeft";

const BAR = 'Bar';
const LARGE_WIDTH_THRESHOLD = 1300;
const MEDIUM_WIDTH_THRESHOLD = 1300;

const styles = {
  outerDiv: {
    position: "absolute",
    bottom: "80px"
  },
  innerDiv: {
    fontSize: 25
  },
  barFormatting: {
    position: "relative",
    borderRadius: 10,
    display: "block",
    marginRight: "25px",
  },
};

/**
 * Component utilizing the Spring package to provide animations for the vertical bars used in Game Two.
 * When a user removes or adds a token to a resource option, it triggers the animation in the Resource Bar.
 * @param {*} props provide the starting and ending height fot the animaiton, in addition to the type of resource the component is representing (which affectst its color and positioning.
 * 
 * @author Eric Doppelt
 */
function InvestBars(props) {
  let destHeightInvest = props.toInvest * props.multiplierInvest;
  if (destHeightInvest > 0) {
    destHeightInvest += 8;
  }

  let destHeightCompete = props.toCompete * props.multiplierCompete;
  if (destHeightCompete > 0) {
    destHeightCompete += 8;
  }

  let barWidth = getWidth(props.windowWidth);

  const springInvest = useSpring({
    from: {
      height: props.fromInvest * 0.20 + "vh",
    },
    to: {
      height: destHeightInvest * 0.20 + "vh",
    },
    config: {
      mass: 1
    },
  });

  const springCompete = useSpring({
    from: {
      height: props.fromCompete * 0.20 + "vh",
    },
    to: {
      height: destHeightCompete * 0.20 + "vh",
    },
    config: {
      mass: 1
    },
  });

  const { classes } = props;
  let backgroundInvest = getBackgroundColor(props.resource + BAR);
  let backgroundCompete = getBackgroundColor(props.resource2 + BAR);
  let payoffInvest = props.currentInvest * props.multiplierInvest;
  let payoffCompete = props.currentCompete * props.multiplierCompete;

  if ((props.currentInvest * props.multiplierInvest == 0 && props.currentCompete * props.multiplierCompete == 0) ||
      (props.currentInvest * props.multiplierInvest == 0 && props.toCompete * props.multiplierCompete == 0) ||
      (props.toInvest * props.multiplierInvest == 0 && props.currentCompete * props.multiplierCompete == 0) ||
      (props.toInvest * props.multiplierInvest == 0 && props.toCompete * props.multiplierCompete == 0)
      ) {
    return (
      <div></div>
    );
  } else if (props.currentInvest * props.multiplierInvest == 0) {
    return (
      <div className={classes.outerDiv} style={{display: 'flex'}}>
        <div style={{flex: 1}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 2}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 3}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>           
        </div>
      </div>
    );
  } else if (props.currentCompete * props.multiplierCompete == 0) {
    return (
      <div className={classes.outerDiv} style={{display: 'flex'}}>
        <div style={{flex: 1}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 2}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 3}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.outerDiv} style={{display: 'flex'}}>
        <div style={{flex: 1}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 2}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
        <div style={{flex: 3}}>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springCompete, backgroundColor: backgroundCompete, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffCompete}
                </div>
            </animated.div>
            <animated.div
            className={classes.barFormatting}
            style={{ ...springInvest, backgroundColor: backgroundInvest, width: barWidth }}
            >
                <div className={classes.innerDiv}>
                    {"+" + payoffInvest}
                </div>
            </animated.div>
        </div>
      </div>
    );
  }
}

function getWidth(windowWidth) {
  if (windowWidth >= LARGE_WIDTH_THRESHOLD) return '60px';
  else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) return '60px';
  else return '45px';
}

export default withStyles(styles)(InvestBars);
