import React, { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Timer from "react-compound-timer";
import { withRouter } from 'react-router-dom';


const TIMER_ID = "timer";
const TEXT_ID = "timerText";
const DIV_ID = "timerDiv";
const ITALIC_FONT = "italic";
const WELCOME_MESSAGE = "The tutorial will begin in:";
const INSTRUCTIONS_MESSAGE = "Please wait while other players join in.";
const MINUTES = "Minutes";
const SECONDS = "Seconds";

const styles = {
    welcomeInstruction: {
      marginTop: "15vh",
    },
    timerInstruction: {
      marginTop: "5vh",
    },
};

/**
 * Component used for the timer in the lobby, which ticks as players enter the game.
 * The timer enabled the start button once it hits zero.
 * The timer is also accompanied by some text which indicates how many players are needed to start the game.
 * @param {*} props provides a method which when called enables the StartButton component to begin the game.
 * 
 * @author Eric Doppelt 
 * @author Ben Li
 */
function InitialLobbyTimer(props) {
    const { classes } = props;
    // obtain lobby end time from currentState prop
    const lobbyEndTime = Date.parse(props.currentState.lobbyEndTime);
    const time = new Date();
    const timeToEnd = lobbyEndTime - time.getTime(); // calculate time from current time to lobby end time

    return (
        <div className={classes.startTimer} id={DIV_ID}>
            <Typography
                className={classes.welcomeInstruction}
                id={TEXT_ID}
                variant={"h4"}
            >
                <Box fontStyle={ITALIC_FONT}>
                    {INSTRUCTIONS_MESSAGE}
                </Box>
            </Typography>
            <Typography
                className={classes.timerInstruction}
                variant={"h3"}
            >
                {WELCOME_MESSAGE}
            </Typography>

            <Timer
                initialTime={timeToEnd}
                direction="backward"
            >
                {() => (
                    <React.Fragment>
                        <Typography variant={"h3"}>
                            <br />
                            <Timer.Minutes /> minutes
                            <br />
                            <Timer.Seconds /> seconds
                            <br />
                        </Typography>   
                    </React.Fragment>
                )}
            </Timer>
        </div>

    );
}

export default withRouter(withStyles(styles)(InitialLobbyTimer));