import React from "react";
import MainPlayerImages from "../../icons/components/MainPlayerImages";
import GameImage from "../../icons/components/GameImage";

const IMAGE = 'image';
const NAME = 'name';

const LARGE_WIDTH_THRESHOLD = 1300;
const MEDIUM_WIDTH_THRESHOLD = 1300;

function getSmallImageAdjustable(playerNumber, isSelf, iconWidth) {
  let imageHeight = iconWidth;
  let imageWidth = iconWidth;
  return (
    <GameImage
      image={MainPlayerImages.images[playerNumber]}
      width={imageWidth}
      height={imageHeight}
      isSelf={isSelf}
    />
  );
}

export default getSmallImageAdjustable;