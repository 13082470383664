import React from "react";
import MainPlayerImages from "../../icons/components/MainPlayerImages";
import GameImage from "../../icons/components/GameImage";

const IMAGE = 'image';
const NAME = 'name';
const LARGE_WIDTH_THRESHOLD = 1300;
const MEDIUM_WIDTH_THRESHOLD = 1300;

function getSmallImage(playerNumber, selectedIndex, frontendIndex, isSelf, windowWidth) {
  let imageHeight = getWidth(windowWidth);
  let imageWidth = getWidth(windowWidth);
  return (
    <GameImage
      image={MainPlayerImages.images[playerNumber]}
      width={imageWidth}
      height={imageHeight}
      isSelf={isSelf}
    />
  );
}

function getWidth(windowWidth) {
    if (windowWidth >= LARGE_WIDTH_THRESHOLD) return '60vw';
    else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) return '60vw';
    else return '45vw';
}

export default getSmallImage;