import React, { useState, useEffect } from "react";
import TutorialScreen from '../../tutorials/TutorialScreen';
import IntroTimer from "../../util/components/IntroTimer";
import Summary from '../../game_one/summary/Summary';
import Routes from '../../util/constants/routes';
import { withRouter } from "react-router-dom";
import { Typography, Box, Button, List, ListItem } from "@material-ui/core";

import gameTwoTutorialSummary from './TutorialSummary_Game2_v2.JPG';

const SHOW_TUTORIAL = true;

const HIDE_TUTORIAL = false;
const HIDE_BUTTON = false;

const ITALIC_FONT = "italic";

const SHOW_GAME_ONE_RESULTS = 0;
const SHOW_GAME_TWO_TUTORIAL = 1;
const SHOW_TUTORIAL_SUMMARY = 2;

const TIMER_MESSAGE = "Game Two";
const TIMER_LENGTH = 198000;

const GAME_TWO_TUTORIAL_LENGTH = 170000; // tutorial about 170 seconds long
const GAME_TWO_TUTORIAL_TEXT = "Game 2 Tutorial";
const DEFAULT_ANIMATION_PAUSE = 1000;

const RESULTS_SUMMARY_SCREEN_DURATION = 10 * 1000; // summary screen about 10 seconds long
const TUTORIAL_SUMMARY_LENGTH = 25000;
const FULL_DIV = "fullDiv";

const GAME_TWO_ROUTE = '/game-two';
/**
 * Component used to visualize the lobby where users wait to enter the game.
 * @param {*} props is used to tell the component the login code, which is provided to the StartTimer.
 *
 * @author Eric Doppelt
 */
function GameTwoIntro(props) {

  const [showTutorial, setShowTutorial] = useState(SHOW_GAME_ONE_RESULTS);

  if (!props.currentState) {
    props.history.push(Routes.LOGIN);
    return (<div></div>);
  }

  if (props.rejoined) {
    props.setRejoined(false);
  }

  const gameTwoIntroEndTime = Date.parse(props.currentState.tutorialEndTime);
  const time = new Date();
  const timeToEnd = gameTwoIntroEndTime - time.getTime();

  let tutorialScreen = getTutorial(props, props.currentState.tutorialURL);
  let summaryScreen = getSummary(props);
  let tutorialSummaryScreen = getTutorialSummary(props);

  var display;
  if (showTutorial == 0) {
    var display = summaryScreen;
  } else if (showTutorial == 1) {
    var display = tutorialScreen;
  } else {
    var display = tutorialSummaryScreen;
  }

  useEffect(() => {
    setTimeout(() => {
      setShowTutorial(SHOW_GAME_TWO_TUTORIAL);
    }, RESULTS_SUMMARY_SCREEN_DURATION);

    setTimeout(() => {
      setShowTutorial(SHOW_TUTORIAL_SUMMARY);
    }, RESULTS_SUMMARY_SCREEN_DURATION + timeToEnd - TUTORIAL_SUMMARY_LENGTH - DEFAULT_ANIMATION_PAUSE);
  });

  return (
      <div className={FULL_DIV}>
        {/* <IntroTimer
          message={TIMER_MESSAGE}
          length={TIMER_LENGTH}
          nextRoute={GAME_TWO_ROUTE}
          currentState={props.currentState}
        /> */}
        {display}
      </div>);
}

function getSummary(props) {
    return(
        <Summary
          selectedIndex={props.selectedIndex}
          allLoginCodes={props.allLoginCodes}
          frontendIndex={props.frontendIndex}
          currentState={props.currentState}
          id={props.id}
          playerData={props.playerData}
        />
    );
}

function getTutorial(props, url) {
  return(
    <TutorialScreen
        videoURL={url}
        showButton={HIDE_BUTTON}s
        initialPause={500}
        videoLength={GAME_TWO_TUTORIAL_LENGTH}
        text={GAME_TWO_TUTORIAL_TEXT}
        currentState={props.currentState}
    />
  );
}

function getTutorialSummary(props) {
  return(
    <div style={{ marginTop: "8vh" }}>
      <Typography variant={"h2"}>
        <Box fontStyle={ITALIC_FONT}>Tutorial Summary</Box>
      </Typography>
      <div>
        <img 
          src={gameTwoTutorialSummary}
          style={{ marginTop: "4vh", width: "70vw" }}
        />
      </div>
    </div>
  )
}

export default withRouter(GameTwoIntro);
