import React from 'react';
import getSmallImageAdjustable from './getSmallImageAdjustable';

const LABEL = 'label'
const SELF = "#faf3b1";
const BORDER_RADIUS = 10;

/**
 * Component used to visualize avatars with a label indicating the avatar's name beneath it.
 * This is used in the PlayerGroup, VerticalPlayerGroup, and MainAvatar files.
 * @param {*} props tells the player to visualize based on its index.
 * 
 * @author Eric Doppelt
 */
function SmallPlayerProfileAdjustable(props) {
    // find avatar index using player id and player data
    var avatarIndex = 0;
    var isSelf = false;

    for (var i = 0; i < props.playerData.length; i++) {
        if (props.playerId == props.playerData[i].id) {
            avatarIndex = props.playerData[i].avatar;
        }
    }

    if (props.playerId == props.id) {
        return(
            <div
            style={{ backgroundColor: SELF, borderRadius: BORDER_RADIUS }}
            >
                <div>
                    {getSmallImageAdjustable(avatarIndex, isSelf, props.iconWidth)}
                </div>
                <div style={{ fontFamily: "sans-serif" }}>
                    YOU
                </div>
            </div>
        )
    } else {
        return(
            <div>
                {getSmallImageAdjustable(avatarIndex, isSelf, props.iconWidth)}
            </div>
        )
    }    
}


export default (SmallPlayerProfileAdjustable);