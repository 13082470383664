import React from "react";
import { Button, withStyles } from "@material-ui/core";
import socket from "../../socketClient";

const CONFIRM_CHOICES_TEXT = "Confirm!";

const COMPETE_INDEX = 0;
const KEEP_INDEX = 1;
const INVEST_INDEX = 2;

const PRIMARY_COLOR = "primary";
const NOTE_TIME = true;

const LARGE_WIDTH_THRESHOLD = 1550;
const MEDIUM_WIDTH_THRESHOLD = 1150;

const styles = {
  confirmButton: {
    position: "absolute",
    top: "20vh",
    marginTop: "280px",
    left: "0px",
    height: "40px",
    borderRadius: "8px",
    alignItems: "center",
    fontSize: "25px",
    backgroundColor: "#002984",
    zIndex: "1"
  },
};

/**
 * Component that allows the submission of choices in Game Two.
 * Handles the web socket call and sends investements passed in as props from Game Two.
 * @param {*} props tell the choices to send in the web socket call.
 *
 * @author Eric Doppelt
 */
function ConfirmButtonTwo(props) {
  const { classes } = props;
  let margin = getMarginLeft(props.windowWidth);

  if (props.submit) {
    sendDecisions(props);
  }

  return (
    <Button
      className={classes.confirmButton}
      style={{ marginLeft: margin }}
      variant={"contained"}
      color={PRIMARY_COLOR}
      disabled={props.disabled}
      onClick={() => handleSubmission(props, props.disableButton, props.setNoteTime, props.showWaitingDiv)}
    >
      {CONFIRM_CHOICES_TEXT}
    </Button>
  );
}

function handleSubmission(props, disableButton, setNoteTime, showWaitingDiv) {
  const competeCount = props.resources[COMPETE_INDEX];
  const investCount = props.resources[INVEST_INDEX];
  const keepCount = props.resources[KEEP_INDEX];

  if (competeCount + investCount + keepCount != 10) {
    props.setNotAllInvested(true);
    setTimeout(() => {
      props.setNotAllInvested(false);
    }, 3000);
    return;
  }
  disableButton();
  setNoteTime(NOTE_TIME);
  showWaitingDiv();
}

function getWidth(windowWidth) {
  if (windowWidth >= LARGE_WIDTH_THRESHOLD) return '200px';
  else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) return '180px';
  else return '160px';
}

function getMarginLeft(windowWidth) {
  if (windowWidth >= LARGE_WIDTH_THRESHOLD) return '10vw';
  else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) return '8.5vw';
  else return '5vw';
}

function sendDecisions(props) {
  const competeCount = props.resources[COMPETE_INDEX];
  const investCount = props.resources[INVEST_INDEX];
  const keepCount = props.resources[KEEP_INDEX];

  const time = new Date();
  const roundStartTime = Date.parse(props.roundStartTime);
  const decisionTime = time.getTime() - roundStartTime - props.animationPause;

  const gameTwoTurnRequest = {
    type: "game-two_turn",
    tokenDistribution: {
      compete: competeCount,
      invest: investCount,
      keep: keepCount
    },
    decisionTime: decisionTime
  };
  props.setMadeMove(true);
  socket.emit("game-action", gameTwoTurnRequest);
  props.clearSelected();
  props.clearSubmission();
}

export default withStyles(styles)(ConfirmButtonTwo);
