import React from "react";
import { useSpring, animated } from "react-spring";
import { withStyles } from "@material-ui/core";
import getBackgroundColor from "../../util/functions/getResourceBackgroundColor";
import { fontFamily } from "@mui/system";

const BAR = 'Bar';
const LARGE_WIDTH_THRESHOLD = 1250;
const MEDIUM_WIDTH_THRESHOLD = 1000;

const styles = {
  outerDiv: {
    position: "absolute",
    bottom: "20px"
  },
  barFormatting: {
    position: "relative",
    borderRadius: 10,
    display: "inline-block",
  }
};

/**
 * Component utilizing the Spring package to provide animations for the vertical bars used in Game Two.
 * When a user removes or adds a token to a resource option, it triggers the animation in the Resource Bar.
 * @param {*} props provide the starting and ending height fot the animaiton, in addition to the type of resource the component is representing (which affectst its color and positioning.
 * 
 * @author Eric Doppelt
 */
function ResourceBar(props) {
  let destHeight = props.to;
  if (destHeight > 0) {
    destHeight += 8;
  }
  
  const spring = useSpring({
    from: {
      height: props.from * 0.5 + "vh",
    },
    to: {
      height: destHeight * 0.5 + "vh",
    },
    config: {
      mass: 1
    },
  });

  const { classes } = props;
  let background = getBackgroundColor(props.resource + BAR);
  
  if (props.current == 0 || props.to == 0) {
    return (
        <div className={classes.outerDiv}>
            <div >
            <animated.div
                className={classes.barFormatting}
                style={{ ...spring, backgroundColor: background, width: props.barWidth}}
            >
                <div style={{ fontSize: props.fontSize, fontFamily: "sans-serif", color: "black" }}>
                    {props.current}
                </div>
            </animated.div>
            </div>
        </div>
    );
  } else {
    return (
      <div className={classes.outerDiv}>
        <div >
          <animated.div
            className={classes.barFormatting}
            style={{ ...spring, backgroundColor: background, width: props.barWidth}}
          >
            <div style={{ fontSize: props.fontSize, fontFamily: "sans-serif", color: "white" }}>
              {props.current}
            </div>
          </animated.div>
        </div>
      </div>
    );
  }
}

function getBarWidth(windowWidth) {
  if (windowWidth >= LARGE_WIDTH_THRESHOLD) {
    return '40px';
  } else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) {
    return '35px';
  } else {
    return '35px';
  }
}

export default withStyles(styles)(ResourceBar);
