import React, { useState, useEffect } from 'react';
import "./App.css";
import { Router, Route, withRouter } from "react-router-dom";
import socket from "./socketClient";
import { withCookies } from "react-cookie";

import RefreshChecker from "./util/components/RefreshChecker";
import WindowChecker from './util/components/WindowChecker';
import BrowserChecker from './util/components/BrowserChecker';
import RulesDialog from "./util/components/RulesDialog";
import PassiveDialog from './passivity/PassiveDialogue';
import RemoveDialog from './passivity/RemoveDialogue'

import Login from "./login/Login";
import Lobby from "./lobby/Lobby";
import AvatarSelector from "../src/avatar_selection/AvatarSelector";
import GameOne from "./game_one/GameOne";
import GameOneIntro from "./game_one/intro/GameOneIntro";
import GameTwoIntro from "./game_two/intro/GameTwoIntro";
import GameTwo from "./game_two/GameTwo";
import Compensation from "./prolific/Compensation";
import InitialLobby from './initial_lobby/InitialLobby';

import Routes from './util/constants/routes';
import { getThemeProps } from '@material-ui/styles';
import SmallPlayerProfileAdjustable from './game_two/results_adjustable_components/SmallPlayerProfileAdjustable';

const INITIAL_CODE = null;
const INITIAL_ID = null;
const INITIAL_GAME_STATE = null;
const INITIAL_AVATAR = 1;

const INITIAL_WINDOW_WIDTH = window.innerWidth;
const INITIAL_WINDOW_HEIGHT = window.innerHeight;

/**
 * Root element that communicates with the backend for game state info and routes to each page with info passed via props.
 *
 * @author Eric Doppelt
 * @author Ben Li
 */
function App(props) {
  const [loginCode, setLoginCode] = useState(INITIAL_CODE);
  const [windowWidth, setWindowWidth] = useState(INITIAL_WINDOW_WIDTH);
  const [windowHeight, setWindowHeight] = useState(INITIAL_WINDOW_HEIGHT);

  // state vars from server
  const [id, setId] = useState(INITIAL_ID); // unique id
  const [currentState, setCurrentState] = useState(INITIAL_GAME_STATE); // complete game state
  const [avatar, setAvatar] = useState(INITIAL_AVATAR); // avatar index
  const [page, setPage] = useState(null); // type attribute of game state
  const [playerData, setPlayerData] = useState([]); // array of player data for all players in the game
  const [passiveDialogueOpen, setPassiveDialogueOpen] = useState(false);
  const [removeDialogueOpen, setRemoveDialogueOpen] = useState(false);
  const [rejoined, setRejoined] = useState(false); // keep track of whether player joined previously

  useEffect(() => {
    socket.on("connect", () => {
      // if id cookie saved, player was in a game previously
      if (props.cookies.get("id")) {
        setRejoined(true);
        setId(props.cookies.get("id"));

        // send enter game request with unique id from cookies
        const enterGameRequest = { id: props.cookies.get("id") };
        socket.emit("enter-game", enterGameRequest, (response) => { });
      } else {
        // otherwise, send null enter game request
        socket.emit("enter-game", null, (response) => {
          setRejoined(response.inGame);
        });
      }
    });

    // listen to state update from server
    socket.on("state-update", (state) => {
      if (state.type == "lobby" ||
        state.type == "game-one_state" ||
        state.type == "game-two-tutorial" ||
        state.type == "game-two_state") {
        // override player avatar data with default avatars
        let modifiedPlayerData = id ? setAvatarsToDefault(id, state.playerData) : setAvatarsToDefault(props.cookies.get("id"), state.playerData);
        setPlayerData(modifiedPlayerData);
        state.playerData = modifiedPlayerData;
      }

      // update current state and page
      setCurrentState(state);
      setPage(state.type);
    });

    // handle disconnection from server
    socket.on("disconnect", () => {
      if (page != "final-results") {
        setRemoveDialogueOpen(true);
        setPassiveDialogueOpen(false);

        // return to initial login page
        props.history.push(Routes.LOGIN);
      }
    });

    return (() => {
      socket.off("disconnect");
      socket.off("state-update");
      socket.off("connect");
    });
  }, [page, playerData]);

  // on page change, route player to the page specified in the type of the game state
  useEffect(() => {
    if (!currentState) {
      return;
    }
    if (currentState.type == "initial-lobby") {
      props.history.push(Routes.INITIAL_LOBBY);
    } else if (currentState.type == "lobby") {
      props.history.push(Routes.LOBBY);
    } else if (currentState.type == "game-one_state") {
      props.history.push(Routes.GAME_ONE);
    } else if (currentState.type == "game-two_state") {
      props.history.push(Routes.GAME_TWO);
    } else if (currentState.type == "game-one-tutorial") {
      props.history.push(Routes.GAME_ONE_INTRO);
    } else if (currentState.type == "game-two-tutorial") {
      props.history.push(Routes.GAME_TWO_INTRO);
    } else if (currentState.type == "final-results") {
      props.history.push(Routes.COMPENSATION);
    }
  }, [page]);

  // on avatar change, send avatar request to server
  useEffect(() => {
    const lobbyAvatarRequest = {
      type: "lobby_avatar",
      avatar: avatar
    };
    socket.emit("game-action", lobbyAvatarRequest);
  }, [avatar]);

  // on id change, save in a cookie
  useEffect(() => {
    if (id) {
      props.cookies.set("id", id, { path: "/" });
    }
  }, [id]);

  return (
    <div className="app">
      <Router history={props.history}>
        <WindowChecker setWindowWidth={setWindowWidth} setWindowHeight={setWindowHeight} />
        <BrowserChecker />

        {/* Add components which show the rules when selected or to prompt the user to ensure they are still playing.*/}
        <RulesDialog />
        <PassiveDialog loginCode={loginCode} passiveDialogueOpen={passiveDialogueOpen} setPassiveDialogueOpen={setPassiveDialogueOpen} page={page} />

        <RemoveDialog removeDialogueOpen={removeDialogueOpen} setRemoveDialogueOpen={setRemoveDialogueOpen} />

        {/* Add routes for the pages in the web app below.*/}

        {/* LOGIN PAGE */}
        <Route
          exact
          path={Routes.LOGIN}
          render={() => (
            <Login
              code={loginCode}
              setLoginCode={setLoginCode}
              currentState={currentState}
              setCurrentState={setCurrentState}
              id={id}
              setId={setId}
            />
          )}
        />

        {/* INITIAL LOBBY PAGE */}
        <Route
          path={Routes.INITIAL_LOBBY}
          render={() => (
            <InitialLobby
              avatar={avatar}
              setAvatar={setAvatar}
              code={loginCode}
              setLoginCode={setLoginCode}
              currentState={currentState}
              setCurrentState={setCurrentState}
              playerData={playerData}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* LOBBY PAGE */}
        <Route
          path={Routes.LOBBY}
          render={() => (
            <Lobby
              avatar={avatar}
              setAvatar={setAvatar}
              code={loginCode}
              setLoginCode={setLoginCode}
              currentState={currentState}
              setCurrentState={setCurrentState}
              playerData={playerData}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* AVATAR SELECTION PAGE */}
        <Route
          path={Routes.AVATAR_SELECTION}
          exact
          render={() => (
            <AvatarSelector
              avatar={avatar}
              setAvatar={setAvatar}
              playerData={playerData}
              setPlayerData={setPlayerData}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              currentState={currentState}
              setCurrentState={setCurrentState}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* GAME ONE INTRO PAGE */}
        <Route
          path={Routes.GAME_ONE_INTRO}
          render={() => (
            <GameOneIntro
              avatar={avatar}
              setAvatar={setAvatar}
              currentState={currentState}
              setCurrentState={setCurrentState}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* GAME ONE PAGE */}
        <Route
          path={Routes.GAME_ONE}
          render={() => (
            <GameOne
              currentState={currentState}
              setCurrentState={setCurrentState}
              id={id}
              playerData={playerData}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              passiveDialogueOpen={passiveDialogueOpen}
              setPassiveDialogueOpen={setPassiveDialogueOpen}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* GAME TWO INTRO PAGE */}
        <Route
          path={Routes.GAME_TWO_INTRO}
          render={() =>
            <GameTwoIntro
              id={id}
              currentState={currentState}
              setCurrentState={setCurrentState}
              playerData={playerData}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          }
        />

        {/* GAME TWO PAGE */}
        <Route
          path={Routes.GAME_TWO}
          render={() => (
            <GameTwo
              currentState={currentState}
              id={id}
              avatar={avatar}
              playerData={playerData}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              setPassiveDialogueOpen={setPassiveDialogueOpen}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

        {/* COMPENSATION PAGE */}
        <Route
          path={Routes.COMPENSATION}
          render={() => (
            <Compensation
              currentState={currentState}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
              id={id}
              rejoined={rejoined}
              setRejoined={setRejoined}
            />
          )}
        />

      </Router>
    </div>
  )
}

// overrides player data, sets self to have avatar 0 (circle), and other players to have avatars 1 through 5
// @param id: id of selfs
// @param playerData: array of player ids and avatars, received fsrom game state
function setAvatarsToDefault(id, playerData) {
  var avatarOffset = 1;

  for (var i = 0; i < 6; i++) {
    if (playerData[i].id != id) {
      playerData[i].avatar = avatarOffset;
      avatarOffset++;
    } else {
      playerData[i].avatar = 0;
    }
  }

  return playerData;
}

export default withCookies(withRouter(App));