import React, { useState } from "react";
import ReactPlayer from "react-player";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";

const PLAY_VIDEO = true;
const PAUSE_VIDEO = false;

const ITALIC_FONT = "italic";
const ENABLE_BUTTON = true;
const DISABLE_BUTTON = false;
const FULL_SCREEN = "100%";
const PRIMARY_COLOR = "primary";

const styles = {
  headerDiv: {
    marginTop: "95px",
  },
  videoOuterDiv: {
    marginTop: "4vh",
  },
  buttonDiv: {
    position: "relative",
    marginTop: '4vh',
    textAlign: 'center'
  }
};

/**
 * Screen used before Game One and Game Two which provides the tutorial video for each Game and enables the button once it ends.
 * @param {*} props is used mainly to provide the relative file path to the tutorial video and the route to link to after it plays.
 * 
 * @author Eric Doppelt
 */
function TutorialScreen(props) {
  const [playVideo, setPlayVideo] = useState(PAUSE_VIDEO);

  setTimeout(() => {
    setPlayVideo(PLAY_VIDEO);
  }, props.initialPause);

  setTimeout(() => {
    setPlayVideo(PAUSE_VIDEO);
  }, props.initialPause + props.videoLength);

  const { classes } = props;
  return (
    <div>
      <div className={classes.headerDiv}>
        <Typography variant={"h2"}>
          <Box fontStyle={ITALIC_FONT}>{props.text}</Box>
        </Typography>
        <Typography variant={"h4"}>
          <Box fontStyle={ITALIC_FONT}>This tutorial has audio. Please make sure the video is unmuted and your volume is turned up.</Box>
        </Typography>
      </div>
      <div className={classes.videoOuterDiv}>
        <iframe
          width={"1000px"}
          height={"500px"}
          src={props.videoURL}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </div>
    </div >
  );
}

export default withStyles(styles)(TutorialScreen);
