/**
 * Constants used to set margins in Game Two.
 *
 * @author Eric Doppelt
 */

const ResourceMargins = {
  COMPETE: "75vw",
  KEEP: "55vw",
  INVEST: "35vw",
  DEFAULT: "0vw",
};

export default ResourceMargins;
