import React, { useState, useEffect } from "react";
import MainAvatar from '../../lobby/MainAvatar';
import TutorialScreen from '../../tutorials/TutorialScreen';
import IntroTimer from "../../util/components/IntroTimer";
import Routes from '../../util/constants/routes';
import { withRouter } from "react-router-dom";
import { Typography, Box, Button, List, ListItem } from "@material-ui/core";

import gameOneTutorialSummary from './TutorialSummary_Game1_v2.JPG';


const SHOW_TUTORIAL = true;

const HIDE_TUTORIAL = false;
const SHOW_BUTTON = true;

const ITALIC_FONT = "italic";

const TIMER_MESSAGE = "Game One";
const NO_PAUSE = 0;
const GAME_ONE_TUTORIAL_LENGTH = 90000;
const GAME_ONE_TUTORIAL_TEXT = "Game 1 Tutorial";
const DEFAULT_ANIMATION_PAUSE = 1000;
const BUTTON_MESSAGE = "See My Avatar";
const FULL_DIV = "fullDiv";
const INTRO_LENGTH = 114000;
const GAME_ONE_ROUTE = '/game-one';
const SUMMARY_LENGTH = 25000;

/**
 * Component used to visualize the lobby where users wait to enter the game.
 * @param {*} props is used to tell the component the login code, which is provided to the StartTimer.
 *
 * @author Eric Doppelt
 */
function GameOneIntro(props) {

  const [showTutorial, setShowTutorial] = useState(SHOW_TUTORIAL);

  if (!props.currentState) {
    props.history.push(Routes.LOGIN);
    return (<div></div>);
  }

  let tutorialScreen = getTutorial(setShowTutorial, props.currentState.tutorialURL);
  let summaryScreen = getSummaryScreen(props);
  var display = showTutorial ? tutorialScreen : summaryScreen;

  if (props.rejoined) {
    props.setRejoined(false);
    tutorialScreen = getTutorial(setShowTutorial);
  }

  const gameOneIntroEndTime = Date.parse(props.currentState.tutorialEndTime);
  const time = new Date();
  const timeToEnd = gameOneIntroEndTime - time.getTime();

  // reset this to DEFAULT_ANIMATION + GAME_ONE_TUTORIAL_LENGTH + DEFAULT_ANIMATION
  useEffect(() => {
    setTimeout(() => {
      setShowTutorial(HIDE_TUTORIAL);
    }, DEFAULT_ANIMATION_PAUSE + timeToEnd - SUMMARY_LENGTH + DEFAULT_ANIMATION_PAUSE);
  });

  return (
      <div className={FULL_DIV}>
        {/* <IntroTimer
          message={TIMER_MESSAGE}
          length={INTRO_LENGTH}
          nextRoute={GAME_ONE_ROUTE}
          currentState={props.currentState}
        /> */}
        {display}
      </div>);
}

function getMainAvatar(props) {
  return(
      <MainAvatar 
          selectedIndex={props.avatar} 
          setSelectedIndex={props.setAvatar}
      />
  );
}

function getSummaryScreen(props) {
  return(
    <div style={{ marginTop: "8vh" }}>
      <Typography variant={"h2"}>
        <Box fontStyle={ITALIC_FONT}>Tutorial Summary</Box>
      </Typography>
      <div>
        <img 
          src={gameOneTutorialSummary}
          style={{ marginTop: "4vh", width: "70vw" }}
        />
      </div>
    </div>
  )
}

function getTutorial(setShowTutorial, url) {
  return(
    <TutorialScreen
      videoURL={url}
      showButton={SHOW_BUTTON}
      hideTutorial={() => setShowTutorial(HIDE_TUTORIAL)}
      initialPause={NO_PAUSE}
      videoLength={GAME_ONE_TUTORIAL_LENGTH}
      text={GAME_ONE_TUTORIAL_TEXT}
      buttonMessage={BUTTON_MESSAGE}
    />
  );
}

export default withRouter(GameOneIntro);
