import React from "react";
import { useSpring, animated } from "react-spring";
import { withStyles } from "@material-ui/core";
import getBackgroundColor from "../../util/functions/getResourceBackgroundColor";
import getMarginLeft from "../../util/functions/getResourceMarginLeft";

const BAR = 'Bar';
const LARGE_WIDTH_THRESHOLD = 1300;
const MEDIUM_WIDTH_THRESHOLD = 1300;

const styles = {
  outerDiv: {
    position: "absolute",
    bottom: "80px"
  },
  innerDiv: {
    fontSize: 25
  },
  barFormatting: {
    position: "relative",
    borderRadius: 10,
  },
};

/**
 * Component utilizing the Spring package to provide animations for the vertical bars used in Game Two.
 * When a user removes or adds a token to a resource option, it triggers the animation in the Resource Bar.
 * @param {*} props provide the starting and ending height fot the animation, in addition to the type of resource the component is representing (which affects its color and positioning.
 * 
 * @author Eric Doppelt
 */
function ResourceBar(props) {
  let barWidth = getWidth(props.windowWidth);

  let destHeight = props.to;
  if (destHeight > 0) {
    destHeight += 8;
  }
  const spring = useSpring({
    from: {
      height: props.from * 0.20 + "vh",
    },
    to: {
      height: destHeight * 0.20 + "vh",
    },
    config: {
      mass: 1
    },
  });

  const { classes } = props;
  let background = getBackgroundColor(props.resource + BAR);

  if (props.current == 0 || props.to == 0) {
    return (
      <div></div>
    );
  } else {
    return (
      <div className={classes.outerDiv}>
        <animated.div
          className={classes.barFormatting}
          style={{ ...spring, backgroundColor: background, width: barWidth }}
        >
          <div className={classes.innerDiv}>
            {"+" + props.current}
          </div>
        </animated.div>
      </div>
    );
  }
}

function getWidth(windowWidth) {
  if (windowWidth >= LARGE_WIDTH_THRESHOLD) return '60px';
  else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) return '60px';
  else return '45px';
}

export default withStyles(styles)(ResourceBar);
