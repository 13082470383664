import React from "react";
import SmallPlayerProfileAdjustable from "../results_adjustable_components/SmallPlayerProfileAdjustable";
import { Typography, withStyles } from "@material-ui/core";
import { Grid, Box } from "@material-ui/core";
import { ResourceNames } from "../../util/constants/game_two/GameTwoBundler";
import ResourceBarAdjustable from "../results_adjustable_components/ResourceBarAdjustable";

const GRID_DIRECTION = "row";
const GRID_JUSTIFY = "center";
const GRID_SPACING = 5;

const COMPETE_INDEX = 0;
const KEEP_INDEX = 1;
const INVEST_INDEX = 2;

const LARGE_WIDTH_THRESHOLD = 1250;
const MEDIUM_WIDTH_THRESHOLD = 1000;

const LARGE_HEIGHT_THRESHOLD = 820;
const MEDIUM_HEIGHT_THRESHOLD = 750;

const styles = {
    individualGroup: {
        position: "relative",
        bottom: "-10vh",
        left: "10vw"
    },
    playerIcon: {
        position: "absolute",
        top: "4vh"
    }
};

/**
 * Component used in the Summary screen which visualizes multiple Player Profiles in a horizontal grid.
 * @param {} props tells which players to visualize in the Group.
 * 
 * @author Eric Doppelt
 * @author Ben Li
 */
function IndividualPlayerSelections(props) {
    const { classes } = props;

    let iconWidth = getIconWidth(props.windowWidth);
    let marginT = getMarginTop(props.windowHeight);
    let boxWidth = getBoxWidth(props.windowWidth);
    let boxHeight = getBoxHeight(props.windowHeight);

    return (
        <div className={classes.individualGroup}>
            <Grid container
                direction={GRID_DIRECTION}
                spacing={GRID_SPACING}
                justify={GRID_JUSTIFY}
            >
                <Grid item>
                    {getResourceBar(ResourceNames.INVEST, INVEST_INDEX, props.results, props.results, props.results, props.windowWidth, props.barWidth, props.fontSize)}
                </Grid>
                <Grid item>
                    {getResourceBar(ResourceNames.KEEP, KEEP_INDEX, props.results, props.results, props.results, props.windowWidth, props.barWidth, props.fontSize)}
                </Grid>
                <Grid item>
                    {getResourceBar(ResourceNames.COMPETE, COMPETE_INDEX, props.results, props.results, props.results, props.windowWidth, props.barWidth, props.fontSize)}
                </Grid>
            </Grid>
            <div className={classes.playerIcon} style={{ left: props.barWidth }}>
                <SmallPlayerProfileAdjustable
                    playerId={props.playerId}
                    id={props.id}
                    playerData={props.playerData}
                    windowWidth={props.windowWidth}
                    iconWidth={iconWidth}
                />
            </div>
        </div>
    );
}

function getResourceBar(resource, resourceIndex, fromResources, toResources, currentResources, windowWidth, barWidth, fontSize) {
    return (
        <ResourceBarAdjustable
            resource={resource}
            from={fromResources[resourceIndex]}
            to={toResources[resourceIndex]}
            current={currentResources[resourceIndex]}
            windowWidth={windowWidth}
            barWidth={barWidth}
            fontSize={fontSize}
        />
    );
}

function getIconWidth(windowWidth) {
    if (windowWidth >= LARGE_WIDTH_THRESHOLD) {
        return '40px';
    } else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) {
        return '35px';
    } else {
        return '35px';
    }
}

function getMarginTop(windowHeight) {
    if (windowHeight >= LARGE_HEIGHT_THRESHOLD) {
        return '11vh';
    } else if (windowHeight >= MEDIUM_HEIGHT_THRESHOLD) {
        return '9vh';
    } else {
        return '9vh';
    }
}

function getBoxWidth(windowWidth) {
    if (windowWidth >= LARGE_WIDTH_THRESHOLD) {
        return '500px';
    } else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) {
        return '425px';
    } else {
        return '425px';
    }
}

function getBoxHeight(windowHeight) {
    if (windowHeight >= LARGE_HEIGHT_THRESHOLD) {
        return '210px';
    } else if (windowHeight >= MEDIUM_HEIGHT_THRESHOLD) {
        return '185px';
    } else {
        return '175px';
    }
}

export default withStyles(styles)(IndividualPlayerSelections);
