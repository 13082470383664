import React from "react";
import PlayerImages from "./PlayerImages";
import MainPlayerImages from "./MainPlayerImages";
import AvatarImages from "./MainPlayerImages";
import GameImage from './GameImage';

const IMAGE = 'image';
const NAME = 'name';
const IMAGE_HEIGHT = '85vh';
const IMAGE_WIDTH = '85vw';

function getImage(playerNumber, selectedIndex, frontendIndex, isSelf) {
  return (
    <GameImage
      image={MainPlayerImages.images[playerNumber]}
      width={IMAGE_WIDTH}
      height={IMAGE_HEIGHT}
      isSelf={isSelf}
    />
  );
  }

export default getImage;