import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { VictoryPie, VictoryLegend } from "victory";

import "../util/stylings/FullScreenDiv.css";
import ResourceBar from "./components/ResourceBar";
import CompeteBars from "./components/CompeteBars";
import InvestBars from "./components/InvestBars";
import HorizontalPlayerGroup from "./components/HorizontalPlayerGroup";
import SmallPlayerProfile from "./components/SmallPlayerProfile";
import ResourceSelector from "./components/ResourceSelector";
import { Typography, withStyles } from "@material-ui/core";
import GameTimer from "../util/components/GameTimer";
import ConfirmButtonTwo from "./components/ConfirmButtonTwo";
import { withRouter } from "react-router-dom";
import TokenCounter from "./tokens/TokenCounter";
import "./results/DelayedBar";
import { ResourceNames } from "../util/constants/game_two/GameTwoBundler";
import getAlerts from './components/getAlerts';
import WaitingDiv from "../util/components/WaitingDiv";
import PayoffHelp from "./components/PayoffHelp";
import getBackgroundColor from "../util/functions/getResourceBackgroundColor";
import IndividualPlayerSelections from "./results/IndividualPlayerSelections";
import ResultsTimer from "./components/ResultsTimer";

import Routes from "../util/constants/routes";


const GROUP_ONE = 1;
const GROUP_TWO = 2;

const GROUP_ONE_TEXT = "One";
const GROUP_TWO_TEXT = "Two";

const LARGE_WIDTH_THRESHOLD = 1450;
const MEDIUM_WIDTH_THRESHOLD = 1200;

const COMPETE_INDEX = 0;
const KEEP_INDEX = 1;
const INVEST_INDEX = 2;

const INITIAL_RESOURCE_DISTRIBUTION = [0, 0, 0];
const RESOURCE_INCREMENTER = 1;

const VERTICAL_SCALAR = 44.2;
const INCREASING = true;
const DECREASING = false;

const ENOUGH_TOKENS = false;
const NOT_ENOUGH_TOKENS = true;

const NEGATIVE_TOKENS = true;
const NOT_NEGATIVE_TOKENS = false;

const NO_TOKENS_SPENT = 0;
const DO_NOT_SUBMIT_DECISIONS = false;
const RESET_TIMER = true;
const DO_NOT_RESET_TIMER = false;

const INITIAL_COMPETE_PAYOFF = 1;
const INITIAL_INVEST_PAYOFF = 1;

const DO_NOT_SHOW_RESULTS = false;
const SHOW_RESULTS = true;

const RESULTS_DELAY_COMPETE = 1000;
const RESULTS_DELAY_KEEP = 2000;
const RESULTS_DELAY_INVEST = 3000;
const RESULTS_DELAY_GROUP_TWO = 3000;

const INITIAL_TOKENS = 10;
const FULL_DIV = "fullDiv";

const END_TURN_WEBSOCKET = "end current turn for game 2";
const END_GAME_WEBSOCKET = "end game 2";

const END_TURN_TEXT = "Results from Previous Turn:";
const GROUP_BOX_WIDTH = "40vw";

const DISABLE_BUTTON = true;
const DO_NOT_DISABLE_BUTTON = false;

const INITIAL_TIME_LEFT = -1;
const DONT_NOTE_TIME = false;

const SHOW_DIV = true
const HIDE_DIV = false

const PLAYER_NUMBERS = [0, 1, 2, 3, 4, 5];
const AVATARS = [0, 1, 2, 3, 4, 5];

const styles = {
    groupOne: {
        position: "relative",
        top: "85vh",
        left: "5vw",
    },
    groupTwo: {
        position: "relative",
        top: "85vh",
        left: "55vw",
    },
    resultsText: {
        position: "relative",
        marginTop: "3vh"
    },
    horizontalGroup: {
        position: "relative",
        top: "35vh",
        left: "32vw"
    },
    tokenText: {
        marginTop: "20px",
        borderRadius: "20px",
        backgroundColor: "#96fc92",
        width: "800px",
        justifyContent: "center",
    },
    groupLabel: {
        position: "relative",
        top: "14vh"
    },
    youLabel: {
        position: "relative",
        top: "14vh",
    },
    otherGroupLabel: {
        position: "relative",
        top: "-2vh"
    },
    pieChartContainer: {
        position: "relative",
        top: "5vh"
    },
    pieChart: {
        position: "relative",
        left: "10vw"
    },
    roundInfo: {
        position: "relative",
        left: "40vw",
        top: "3vh",
        borderRadius: "20px",
        backgroundColor: "#96fc92",
        width: "400px",
        justifyContent: "center"
    }
};

/**
 * All-encompassing class used to run the second game of the psych experiment.
 * Leverages components in the subpackage game_two.components to create a game following the rules outlined in the GameTwoTutorial.png file.
 * @param {*} props is used to prove the login code of the player and all login codes for all players.
 * 
 * @author Eric Doppelt
 */
function GameTwo(props) {
    const totalTokens = INITIAL_TOKENS;
    const [fromResources, setFromResources] = useState(INITIAL_RESOURCE_DISTRIBUTION);
    const [toResources, setToResources] = useState(fromResources);
    const [currentResources, setCurrentResources] = useState(INITIAL_RESOURCE_DISTRIBUTION);

    const [notAllInvested, setNotAllInvested] = useState(false); // determines whether alert is displayed if the player has not invested all of their tokens yet
    const [notEnoughTokens, setNotEnoughTokens] = useState(ENOUGH_TOKENS);
    const [negativeTokens, setNegativeTokens] = useState(NOT_NEGATIVE_TOKENS);
    const [tokensSpent, setTokensSpent] = useState(NO_TOKENS_SPENT);
    const [resetTimer, setResetTimer] = useState(DO_NOT_RESET_TIMER);
    const [submitDecisions, setSubmitDecisions] = useState(DO_NOT_SUBMIT_DECISIONS);
    const [payoffCompete, setCompetePayoff] = useState(INITIAL_COMPETE_PAYOFF);
    const [payoffInvest, setInvestPayoff] = useState(INITIAL_INVEST_PAYOFF);
    const [showResults, setShowResults] = useState(DO_NOT_SHOW_RESULTS);
    const [groupOneResults, setGroupOneResults] = useState(INITIAL_RESOURCE_DISTRIBUTION);
    const [groupTwoResults, setGroupTwoResults] = useState(INITIAL_RESOURCE_DISTRIBUTION);

    const [timeLeft, setTimeLeft] = useState(INITIAL_TIME_LEFT);
    const [noteTime, setNoteTime] = useState(DONT_NOTE_TIME);
    const [showWaitingDiv, setShowWaitingDiv] = useState(HIDE_DIV);

    const [disableButton, setDisableButton] = useState(DO_NOT_DISABLE_BUTTON);

    const [selectorEnabled, setSelectorEnabled] = useState(true);

    const [madeMove, setMadeMove] = useState(true);

    if (!props.currentState) {
        props.history.push(Routes.LOGIN);
        return (<div></div>);
    }

    // state variables based on state object received from server
    const currentState = props.currentState;
    const [winners, setWinners] = useState(["n/a", "n/a", "n/a"]);
    const [losers, setLosers] = useState(["n/a", "n/a", "n/a"]);
    const [round, setRound] = useState(0);
    const [competeCoefficient, setCompeteCoefficient] = useState(0);
    const [investCoefficient, setInvestCoefficient] = useState(0);

    useEffect(() => {
        if (props.currentState.type != "game-two_state") {
            return;
        }

        if (!madeMove) {
            props.setPassiveDialogueOpen(true);
        }

        setTokensSpent(NO_TOKENS_SPENT);
        setMadeMove(false);

        if (props.rejoined) {
            // handle player rejoining in the middle of a round, go straight to selection screen
            setWinners(props.currentState.winners);
            setLosers(props.currentState.losers);
            setRound(props.currentState.round);
            setCompeteCoefficient(props.currentState.competeCoefficient);
            setInvestCoefficient(props.currentState.investCoefficient);
            setShowWaitingDiv(HIDE_DIV);
            setCompetePayoff(props.currentState.competeCoefficient);
            setInvestPayoff(props.currentState.investCoefficient);
            setResetTimer(RESET_TIMER);
            setCurrentResources(INITIAL_RESOURCE_DISTRIBUTION);
            props.setRejoined(false);
            setDisableButton(true);
            setSelectorEnabled(false);
        } else if (props.currentState.round > 0) {
            clearResources(setFromResources, setToResources, toResources, setTokensSpent)
            setShowWaitingDiv(HIDE_DIV);
            setCompetePayoff(props.currentState.competeCoefficient);
            setInvestPayoff(props.currentState.investCoefficient);
            setResetTimer(RESET_TIMER);
            setCurrentResources(INITIAL_RESOURCE_DISTRIBUTION);
            setShowResults(SHOW_RESULTS);
            setWinners(props.currentState.winners);
            setLosers(props.currentState.losers);
            setRound(props.currentState.round);
            // show resourceResultsView first, and then go to resourceChoiceView
            setGroupOneResults([
                props.currentState.teamResults.winnerTeam.totalTokenDistribution.compete,
                props.currentState.teamResults.winnerTeam.totalTokenDistribution.keep,
                props.currentState.teamResults.winnerTeam.totalTokenDistribution.invest
            ]);
            setGroupTwoResults([
                props.currentState.teamResults.loserTeam.totalTokenDistribution.compete,
                props.currentState.teamResults.loserTeam.totalTokenDistribution.keep,
                props.currentState.teamResults.loserTeam.totalTokenDistribution.invest
            ]);
            setTimeout(() => {
                setDisableButton(DO_NOT_DISABLE_BUTTON);
                setSelectorEnabled(true);
                setShowResults(DO_NOT_SHOW_RESULTS);
            }, props.currentState.resultTime);
        } else {
            setWinners(props.currentState.winners);
            setLosers(props.currentState.losers);
            setRound(props.currentState.round);
            setCompeteCoefficient(props.currentState.competeCoefficient);
            setInvestCoefficient(props.currentState.investCoefficient);
            setShowWaitingDiv(HIDE_DIV);
            setCompetePayoff(props.currentState.competeCoefficient);
            setInvestPayoff(props.currentState.investCoefficient);
            setResetTimer(RESET_TIMER);
            setCurrentResources(INITIAL_RESOURCE_DISTRIBUTION);
        }
    }, [props.currentState]);

    const { classes } = props;

    const roundEndTime = Date.parse(props.currentState.roundEndTime);
    const time = new Date();
    const roundLength = roundEndTime - time.getTime();

    let resourceResultsView = getResourceResults(
        classes,
        props,
        winners,
        losers,
        props.windowWidth,
        props.windowHeight,
        payoffInvest,
        payoffCompete);


    let resourceChoiceView = getResourceChoices(
        classes,
        roundLength,
        winners,
        losers,
        props,
        setFromResources,
        setToResources,
        fromResources,
        toResources,
        totalTokens,
        setNotEnoughTokens,
        setNegativeTokens,
        tokensSpent,
        setTokensSpent,
        setCurrentResources,
        currentResources,
        payoffInvest,
        payoffCompete,
        resetTimer,
        setResetTimer,
        setSubmitDecisions,
        submitDecisions,
        noteTime,
        setNoteTime,
        timeLeft,
        setTimeLeft,
        disableButton,
        setDisableButton,
        selectorEnabled,
        setSelectorEnabled,
        showWaitingDiv,
        setShowWaitingDiv,
        madeMove,
        setMadeMove,
        setNotAllInvested
    );

    let resourceView = showResults ? resourceResultsView : resourceChoiceView;
    // let resourceView = resourceResultsView;
    // let resourceView = resourceChoiceView;

    var tokensRemaining = 10 - currentResources[0] - currentResources[1] - currentResources[2];

    return (
        <div className={FULL_DIV}>
            {resourceView}
            {getAlerts(tokensRemaining, notAllInvested, setNotAllInvested, notEnoughTokens, setNotEnoughTokens, negativeTokens, setNegativeTokens)}
        </div>
    );
}

function getResourceResults(classes, props, winners, losers, windowWidth, windowHeight, payoffInvest, payoffCompete, setTokensSpent) {
    // TODO: copied code from resource choice function, factor out common code
    let winnerIndices = getAvatarIndices(winners, props.currentState.playerData);
    let loserIndices = getAvatarIndices(losers, props.currentState.playerData);
    let teamIndices = [0, 0, 0];
    let teamIds = ["", "", ""];
    let otherTeamIndices = [0, 0, 0];
    let otherTeamIds = ["", "", ""];
    if (winners.includes(props.id)) {
        teamIndices = winnerIndices;
        teamIds = winners;
        otherTeamIndices = loserIndices;
        otherTeamIds = losers;
    } else {
        teamIndices = loserIndices;
        teamIds = losers;
        otherTeamIndices = winnerIndices;
        otherTeamIds = winners;
    }

    // keep track of total selections for player's team and other team
    var teamCounts = {
        keepCount: 0,
        investCount: 0,
        competeCount: 0
    };

    var otherTeamCounts = {
        keepCount: 0,
        investCount: 0,
        competeCount: 0
    };

    // get player selections from state
    var teamSelections = [];
    var otherTeamSelections = [];
    let playerSelections = [];

    if (props.currentState.playerSelections) {
        playerSelections = props.currentState.playerSelections
    }

    var investCoefficient = props.currentState.investCoefficient;
    var competeCoefficient = props.currentState.competeCoefficient;

    // get team selections/counts and other team selections/counts
    for (let i = 0; i < playerSelections.length; i++) {
        let id = playerSelections[i].id;
        let keepCount = playerSelections[i].keep;
        let investCount = playerSelections[i].invest / investCoefficient;
        let competeCount = playerSelections[i].compete / competeCoefficient;
        let selection = {
            id: id,
            results: [competeCount, keepCount, investCount]
        }
        if (teamIds.includes(id)) {
            teamSelections.push(selection);
            teamCounts.keepCount += keepCount;
            teamCounts.investCount += investCount;
            teamCounts.competeCount += competeCount;
        } else {
            otherTeamSelections.push(selection);
            otherTeamCounts.keepCount += keepCount;
            otherTeamCounts.investCount += investCount;
            otherTeamCounts.competeCount += competeCount;
        }
    }

    // pie chart data
    var teamTotal = teamCounts.investCount + teamCounts.keepCount + teamCounts.competeCount;
    var teamChartData = [
        { x: "Invest", y: teamCounts.investCount },
        { x: "Keep", y: teamCounts.keepCount },
        { x: "Compete", y: teamCounts.competeCount }
    ];

    var otherTeamTotal = otherTeamCounts.investCount + otherTeamCounts.keepCount + otherTeamCounts.competeCount;
    var otherTeamChartData = [
        { x: "Invest", y: otherTeamCounts.investCount },
        { x: "Keep", y: otherTeamCounts.keepCount },
        { x: "Compete", y: otherTeamCounts.competeCount }
    ];

    var chartColors = [
        getBackgroundColor(ResourceNames.INVEST + "Bar"),
        getBackgroundColor(ResourceNames.KEEP + "Bar"),
        getBackgroundColor(ResourceNames.COMPETE + "Bar")
    ];

    var tokensEarned = 0;
    if (props.currentState.playerResults) {
        tokensEarned = props.currentState.playerResults.netTokens;
    }

    var textSpacing = getTextSpacing(windowWidth);

    return (
        <div>
            <div>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h4" className={classes.tokenText}>
                        On this round you earned: {tokensEarned} tokens
                    </Typography>
                </Box>
            </div>
            <div style={{ display: "flex" }} className={classes.pieChartContainer}>
                <ResultsTimer
                    initialTime={props.currentState.resultTime}
                />
                <div style={{ flex: 2 }}>
                    <Typography variant="h4">
                        Players in your group chose:
                    </Typography>
                    <div style={{ width: "25vw", height: "25vw" }} className={classes.pieChart}>
                        <VictoryPie 
                            data={teamChartData}
                            colorScale={[
                                getBackgroundColor(ResourceNames.INVEST + "Bar"),
                                getBackgroundColor(ResourceNames.KEEP + "Bar"),
                                getBackgroundColor(ResourceNames.COMPETE + "Bar")
                            ]}
                            style={{ labels: { fill: "white", fontSize: 25, fontWeight: "bold"} }}
                            labelRadius={80}
                            labels={[parseInt(teamCounts.investCount / teamTotal * 100) + "%",
                                     parseInt(teamCounts.keepCount / teamTotal * 100) + "%",
                                     parseInt(teamCounts.competeCount / teamTotal * 100) + "%"]}
                        />
                    </div>
                    <Grid container
                        direction={"row"}
                        spacing={10}
                    >
                        {teamSelections.map((element) => {
                        return (
                            <Grid item>
                                <IndividualPlayerSelections
                                    id={props.id}
                                    playerId={element.id}
                                    teamIndices={teamIndices}
                                    otherTeamIndices={otherTeamIndices}
                                    teamIds={teamIds}
                                    otherTeamIds={otherTeamIds}
                                    playerData={props.playerData}
                                    iconWidth={"50px"}
                                    barWidth={"40px"}
                                    groupSpacing={"3vw"}
                                    fontSize={"17px"}
                                    windowWidth={windowWidth}
                                    windowHeight={windowHeight}
                                    results={element.results}
                                    payoffInvest={payoffInvest}
                                    payoffCompete={payoffCompete}
                                />
                            </Grid>
                            );
                        })}
                    </Grid>
                </div>
                <div style={{ flex: 0.5 }}>
                    <VictoryLegend
                        title="Legend"
                        centerTitle
                        orientation="Vertical"
                        gutter={20}
                        style={{ border: { stroke: "black" }, title: {fontSize: 20 } }}
                        data={[
                        { name: "Invest" },
                        { name: "Keep" },
                        { name: "Compete" }
                        ]}
                        colorScale={[
                            getBackgroundColor(ResourceNames.INVEST + "Bar"),
                            getBackgroundColor(ResourceNames.KEEP + "Bar"),
                            getBackgroundColor(ResourceNames.COMPETE + "Bar")
                        ]}
                        width={150}
                    />
                </div>
                <div style={{ flex: 2 }}>
                    <Typography variant="h4">
                        Players in the other group chose:
                    </Typography>
                    <div style={{ width: "25vw", height: "25vw" }} className={classes.pieChart}>
                        <VictoryPie
                            data={otherTeamChartData}
                            colorScale={[
                                getBackgroundColor(ResourceNames.INVEST + "Bar"),
                                getBackgroundColor(ResourceNames.KEEP + "Bar"),
                                getBackgroundColor(ResourceNames.COMPETE + "Bar")
                            ]}
                            labelRadius={80}
                            style={{ labels: { fill: "white", fontSize: 25, fontWeight: "bold"} }}
                            labels={[parseInt(otherTeamCounts.investCount / otherTeamTotal * 100) + "%",
                                     parseInt(otherTeamCounts.keepCount / otherTeamTotal * 100) + "%",
                                     parseInt(otherTeamCounts.competeCount / otherTeamTotal * 100) + "%"]}
                        />
                    </div>
                    <Grid container
                        direction={"row"}
                        spacing={10}
                    >
                        {otherTeamSelections.map((element) => {
                        return (
                            <Grid item>
                                <IndividualPlayerSelections
                                    id={props.id}
                                    playerId={element.id}
                                    teamIndices={otherTeamIndices}
                                    otherTeamIndices={teamIndices}
                                    teamIds={otherTeamIds}
                                    otherTeamIds={teamIds}
                                    playerData={props.playerData}
                                    iconWidth={"50px"}
                                    barWidth={"40px"}
                                    groupSpacing={"3vw"}
                                    fontSize={"17px"}
                                    windowWidth={windowWidth}
                                    windowHeight={windowHeight}
                                    results={element.results}
                                    payoffInvest={payoffInvest}
                                    payoffCompete={payoffCompete}
                                />
                            </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

function getResourceChoices(classes, roundLength, winners, losers, props, setFromResources, setToResources, fromResources, toResources, totalTokens, setNotEnoughTokens, setNegativeTokens,
    tokensSpent, setTokensSpent, setCurrentResources, currentResources, payoffInvest, payoffCompete, resetTimer, setResetTimer, setSubmitDecisions, submitDecisions,
    noteTime, setNoteTime, timeLeft, setTimeLeft, disableButton, setDisableButton, selectorEnabled, setSelectorEnabled, showWaitingDiv, setShowWaitingDiv, madeMove, setMadeMove, setNotAllInvested) {
    
    var animationPause = props.currentState.resultTime;

    if (props.rejoined || props.currentState.round == 0) {
        animationPause = 0;
    }

    let winnerIndices = getAvatarIndices(winners, props.currentState.playerData);
    let loserIndices = getAvatarIndices(losers, props.currentState.playerData);
    let teamIndices = [0, 0, 0];
    let teamIds = ["", "", ""];
    let otherTeamIndices = [0, 0, 0];
    let otherTeamIds = ["", "", ""];
    if (winners.includes(props.id)) {
        teamIndices = winnerIndices;
        teamIds = winners;
        otherTeamIndices = loserIndices;
        otherTeamIds = losers;
    } else {
        teamIndices = loserIndices;
        teamIds = losers;
        otherTeamIndices = winnerIndices;
        otherTeamIds = winners;
    }

    var totalRounds = props.currentState.totalRounds;
    var currentRound = props.currentState.round + 1;

    return (
        <div>
            <PayoffHelp />
            <WaitingDiv show={showWaitingDiv} windowWidth={props.windowWidth} />
            <TokenCounter tokens={totalTokens - tokensSpent} windowHeight={props.windowHeight} windowWidth={props.windowWidth} />
            <div className={classes.roundInfo}>
            <Typography variant="h5">
                Round {currentRound} out of {totalRounds}
            </Typography>
            </div>
            <GameTimer
                roundLength={roundLength}
                setSubmitDecisions={setSubmitDecisions}
                resetTimer={resetTimer}
                setResetTimer={setResetTimer}
                noteTime={noteTime}
                setNoteTime={setNoteTime}
                setTimeLeft={setTimeLeft}
                windowWidth={props.windowWidth}
                windowHeight={props.windowHeight}
            />
            <ConfirmButtonTwo
                id={props.id}
                submit={submitDecisions}
                clearSubmission={() => setSubmitDecisions(DO_NOT_SUBMIT_DECISIONS)}
                resources={currentResources}
                clearSelected={() => clearResources(setFromResources, setToResources, toResources, setTokensSpent)}
                loginCode={props.loginCode}
                disabled={disableButton}
                disableButton={() => {
                    setDisableButton(DISABLE_BUTTON)
                    setSelectorEnabled(false)
                }
                }
                timeLeft={timeLeft}
                setNoteTime={setNoteTime}
                windowWidth={props.windowWidth}
                windowHeight={props.windowHeight}
                experimentID={props.experimentID}
                showWaitingDiv={() => setShowWaitingDiv(SHOW_DIV)}
                madeMove={madeMove}
                setMadeMove={setMadeMove}
                roundStartTime={props.currentState.roundStartTime}
                animationPause={animationPause}
                setNotAllInvested={setNotAllInvested}
            />

            <div style={{ display: 'flex' }} className={classes.horizontalGroup}>
                <div>
                    <div className={classes.groupLabel}>
                        <Typography variant="h5">
                            Your Group
                        </Typography>
                    </div>
                    <HorizontalPlayerGroup
                        players={teamIndices}
                        id={props.id}
                        playerData={props.playerData}
                        groupIds={teamIds}
                        className={classes.horizontalGroup}
                        windowWidth={props.windowWidth}
                    />
                    {getInvestBars(ResourceNames.INVEST, ResourceNames.COMPETE, INVEST_INDEX, COMPETE_INDEX, fromResources, toResources, currentResources, props.windowWidth, payoffInvest, payoffCompete)}
                </div>

                <div style={{ marginLeft: '10vw' }}>
                    <div className={classes.youLabel}>
                        <Typography variant="h5">
                            You
                        </Typography>
                    </div>
                    <SmallPlayerProfile
                        playerId={props.id}
                        id={props.id}
                        playerData={props.playerData}
                        windowWidth={props.windowWidth}
                    />
                    {getResourceBar(ResourceNames.KEEP, KEEP_INDEX, fromResources, toResources, currentResources, props.windowWidth)}
                </div>

                <div style={{ marginLeft: '10vw' }}>
                    <div className={classes.otherGroupLabel}>
                        <Typography variant="h5">
                            Other Group
                        </Typography>
                    </div>
                    <HorizontalPlayerGroup
                        players={otherTeamIndices}
                        id={props.id}
                        playerData={props.playerData}
                        groupIds={otherTeamIds}
                        className={classes.horizontalGroup}
                        windowWidth={props.windowWidth}
                    />
                    {getCompeteBars(ResourceNames.COMPETE, COMPETE_INDEX, fromResources, toResources, currentResources, props.windowWidth, payoffCompete)}
                </div>
            </div>

            {getResourceButton(ResourceNames.KEEP, KEEP_INDEX, setFromResources, setToResources, toResources, totalTokens, setNotEnoughTokens,
                setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources, props.windowWidth, 1, selectorEnabled)}
            {getResourceButton(ResourceNames.INVEST, INVEST_INDEX, setFromResources, setToResources, toResources, totalTokens, setNotEnoughTokens,
                setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources, props.windowWidth, payoffInvest, selectorEnabled)}
            {getResourceButton(ResourceNames.COMPETE, COMPETE_INDEX, setFromResources, setToResources, toResources, totalTokens, setNotEnoughTokens,
                setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources, props.windowWidth, payoffCompete, selectorEnabled)}
        </div>
    );
}

function clearResources(setFromResources, setToResources, toResources, setTokensSpent) {
    setFromResources(toResources);
    setToResources(INITIAL_RESOURCE_DISTRIBUTION);
    // setTokensSpent(NO_TOKENS_SPENT);
}

function scaleHeight(resourceTokens, totalTokens) {
    let resourceProportion = resourceTokens / totalTokens;
    return resourceProportion * VERTICAL_SCALAR;
}

function getResourceButton(resource, resourceIndex, setFromResources, setToResources, toResources, totalTokens, setNotEnoughTokens,
    setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources, windowWidth, payoff, selectorEnabled) {
    return (
        <ResourceSelector
            resource={resource}
            currentResources={currentResources}
            resourceIndex={resourceIndex}
            payoff={payoff}
            addToken={() => {
                if (selectorEnabled) {
                    updateResource(resourceIndex, setFromResources, setToResources, toResources, totalTokens, INCREASING, setNotEnoughTokens,
                        setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources)
                }
            }
            }
            removeToken={() => {
                if (selectorEnabled) {
                    updateResource(resourceIndex, setFromResources, setToResources, toResources, totalTokens, DECREASING, setNotEnoughTokens,
                        setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources)
                }
            }
            }
            windowWidth={windowWidth}
        />
    );
}

function getResourceBar(resource, resourceIndex, fromResources, toResources, currentResources, windowWidth) {
    return (
        <ResourceBar
            resource={resource}
            from={fromResources[resourceIndex]}
            to={toResources[resourceIndex]}
            current={currentResources[resourceIndex]}
            windowWidth={windowWidth}
        />
    );
}

function getCompeteBars(resource, resourceIndex, fromResources, toResources, currentResources, windowWidth, multiplier) {
    return (
        <CompeteBars
            resource={resource}
            from={fromResources[resourceIndex]}
            to={toResources[resourceIndex]}
            current={currentResources[resourceIndex]}
            windowWidth={windowWidth}
            multiplier={multiplier}
        />
    );
}

function getInvestBars(resource, resource2, resourceIndex, resource2Index, fromResources, toResources, currentResources, windowWidth, multiplierInvest, multiplierCompete) {
    return (
        <InvestBars
            resource={resource}
            resource2={resource2}
            fromInvest={fromResources[resourceIndex]}
            toInvest={toResources[resourceIndex]}
            fromCompete={fromResources[resource2Index]}
            toCompete={toResources[resource2Index]}
            currentInvest={currentResources[resourceIndex]}
            currentCompete={currentResources[resource2Index]}
            windowWidth={windowWidth}
            multiplierInvest={multiplierInvest}
            multiplierCompete={multiplierCompete}
        />
    );
}

function updateResource(resourceIndex, setFromResources, setToResources, originalResources, totalTokens, isIncreasing, setNotEnoughTokens,
    setNegativeTokens, tokensSpent, setTokensSpent, setCurrentResources, currentResources) {
    let addTokenOffset;

    if (isIncreasing) {
        if (tokensSpent >= totalTokens) {
            setNotEnoughTokens(NOT_ENOUGH_TOKENS);
            setTimeout(() => {
                setNotEnoughTokens(false);
            }, 3000);
            return;
        }
        addTokenOffset = 1;
    } else {
        if (currentResources[resourceIndex] === 0) {
            setNegativeTokens(NEGATIVE_TOKENS);
            setTimeout(() => {
                setNegativeTokens(false);
            }, 3000);
            return;
        }
        addTokenOffset = -1;
    }

    let fromResources = originalResources.slice(0);
    let toResources = fromResources.slice(0);
    toResources[resourceIndex] +=
        addTokenOffset * scaleHeight(RESOURCE_INCREMENTER, totalTokens);

    let newCurrentResources = currentResources.slice(0);
    newCurrentResources[resourceIndex] += addTokenOffset;

    setCurrentResources(newCurrentResources);
    setTokensSpent(addTokenOffset + tokensSpent);
    setFromResources(fromResources);
    setToResources(toResources);
}

function getAvatarIndices(groupIds, playerData) {
    var indices = [];

    for (let i = 0; i < playerData.length; i++) {
        for (let j = 0; j < groupIds.length; j++) {
            if (groupIds[j] === playerData[i].id) {
                indices.push(playerData[i].avatar)
            }
        }
    }
    return indices;
}

function getTextSpacing(windowWidth) {
    if (windowWidth >= LARGE_WIDTH_THRESHOLD) {
        return '-11vh';
    } else if (windowWidth >= MEDIUM_WIDTH_THRESHOLD) {
        return '-5vh';
    } else {
        return '-3vh';
    }
}

export default withRouter(withStyles(styles)(GameTwo));