
import React, { useState, useEffect } from 'react';
import {Button, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import socket from "../socketClient";
import { withRouter } from 'react-router-dom';

const MESSAGE = 'Are you still there?';
// const SUBMESSAGE= 'No response detected. A random choice will be made on your behalf. Please make sure to hit confirm! after making your selections. If you miss 5 rounds, you will be removed from the game..';
const SUBMESSAGE_GAME_ONE = 'No response detected. Please make sure to select two players and hit confirm! If you miss 5 rounds, you will be removed from the game.';
const SUBMESSAGE_GAME_TWO = 'No response detected. A random choice will be made on your behalf. Please make sure to press the confirm button after making your selections. If you miss 5 rounds, you will be removed from the game.';
const BUTTON_VARIANT = 'contained';
const YES = 'Yes';
const YES_COLOR = 'red';
const NO = 'No';
const NO_COLOR = 'blue';
const CHECK_PASSIVITY_WEBSOCKET = "check passivity";
const ACTIVE_PLAYER_WEBSOCKET = "active player";
const INACTIVE_PLAYER_WEBSOCKET = "inactive player";

const OPEN_DIALOGUE = true;
const CLOSE_DIALOGUE = false;

const RESPONDED = true;
const DIDNT_RESPOND = false;
const TIME_TO_RESPOND = 15000;

const styles = ({
    loginButton: {
        marginTop: '60px',
        width: '200px',
        height: '50px',
    },
});


function PassiveAlert(props) {

    
    const [open, setOpen] = useState(CLOSE_DIALOGUE);
    const [responded, setResponded] = useState(RESPONDED);
    const [submessage, setSubmessage] = useState("");

    useEffect(() => {
        if (props.page == 'game-one_state') {
            setSubmessage(SUBMESSAGE_GAME_ONE);
        } else {
            setSubmessage(SUBMESSAGE_GAME_TWO);
        }
    }, []);

    return(
        <Dialog
            open={props.passiveDialogueOpen}
        >
            <DialogTitle>{MESSAGE}</DialogTitle>
            <DialogContent>
                <DialogContentText>{submessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => emitSocket(ACTIVE_PLAYER_WEBSOCKET, props.experimentID, props.loginCode, props.setPassiveDialogueOpen, setResponded)} variant={BUTTON_VARIANT} style={{backgroundColor: '#b54750', color: 'white', fontWeight: 'bold'}}>
                    {YES}
                </Button>
                <Button onClick={() => exitGame(INACTIVE_PLAYER_WEBSOCKET, props.experimentID, props.loginCode, props.setPassiveDialogueOpen, setResponded, props)} variant={BUTTON_VARIANT} style={{backgroundColor: '#385bc7', color: 'white', fontWeight: 'bold'}}>
                    {NO}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function exitGame(webSocket, experimentID, loginCode, setOpen, setResponded, props) {
    setOpen(CLOSE_DIALOGUE)
    socket.close()
    props.history.push('/');
}

function emitSocket(webSocket, experimentID, loginCode, setOpen, setResponded) {
    setOpen(CLOSE_DIALOGUE)
}

export default withRouter(withStyles(styles)(PassiveAlert));
