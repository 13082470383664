import React from "react";
import SmallPlayerProfile from "./SmallPlayerProfile";
import { Grid } from "@material-ui/core";

const GRID_DIRECTION = "row";
const GRID_JUSTIFY = "center";
const GRID_SPACING = 3;

/**
 * Component used in the Summary screen which visualizes multiple Player Profiles in a horizontal grid.
 * @param {} props tells which players to visualize in the Group.
 * 
 * @author Eric Doppelt
 */
function HorizontalPlayerGroup(props) {
  return (
    <Grid
    container
    direction={GRID_DIRECTION}
    spacing={GRID_SPACING}
    >
    {props.groupIds.map((element) => {
        return (
        <Grid item>
            <SmallPlayerProfile
                playerId={element}
                selectedIndex={props.selectedIndex}
                frontendIndex={props.frontendIndex}
                id={props.id}
                playerData={props.playerData}
                windowWidth={props.windowWidth}
            />
        </Grid>
        );
    })}
    </Grid>
  );
}

export default HorizontalPlayerGroup;
