import React from "react";
import { withRouter } from "react-router-dom";

import Routes from '../util/constants/routes';
import InitialLobbyTimer from "./InitialLobbyTimer";


const NOT_DISABLED = false;

const styles = {
  avatarButton: {
    marginTop: '10vh',
  },
};

/**
 * High-level component used to visualize the lobby where users wait to enter the game.
 *
 * @author Eric Doppelt
 */
function InitialLobby(props) {

  if (!props.currentState) {
    props.history.push(Routes.LOGIN);
    return (<div></div>);
  }

  // reset the rejoined state variable
  if (props.rejoined) {
    props.setRejoined(false);
  }

  return (
    <div>
      {/* render time based on lobbyEndTime in currentState prop */}
      <InitialLobbyTimer
        currentState={props.currentState}
        setCurrentState={props.setCurrentState}
      >
      </InitialLobbyTimer>
    </div>
  );
}

export default withRouter(InitialLobby);
