import React from "react";

// TODO: Delete this.
function GameImage(props) {
  return (
    <img
      src={props.image}
      width={props.width}
      height={props.height}
    />
  );
}

export default GameImage;
